import config from "./config";

/**
 * UserAgent used for in-app experience.
 *
 * @type {string}
 */
const APP_USER_AGENT = "ProbaMDD";

/**
 * Checks if the current browser is the in-app experience
 *
 * @returns {boolean}
 */
export const isApp = () => {
	// return true;
	return navigator.userAgent.includes(APP_USER_AGENT);
}

/**
 * Sets the js variable as a CSS variable.
 *
 * @param name {string} Will be prepended with `--`
 * @param value {string}
 */
export const setCssVariable = (name, value) => {
	let root = document.documentElement;

	root.style.setProperty(`--${name}`, value);
}

export const toCachedUrl = (url = null) => {
	if (!url) {
		return;
	}

	const cacheUrl = config('media.base_url', 'https://media.graphcms.com');

	return url.replace('https://media.graphcms.com', cacheUrl);
}

export const openUrl = (fullUrl) => {
	const url = fullUrl
		.replace('https://', '')
		.replace('http://', '');

	const encoded = encodeURIComponent(url);

	if (isApp()) {
		window.location.href = `uniwebview://openURL?url=${encoded}`
		return
	}

	window.open(fullUrl, '_blank').focus();
}

export const viewPage = (pagePath, pageTitle) => {
	trackEvent('page_view', {
		page_title: pageTitle,
		page_path: pagePath,
	});
}

export const triggerBeacon = (totemReference) => {
	trackEvent('found_beacon', {
		'totem_reference': totemReference
	})
}

export const trackEvent = (action, custom = {}) => {
	gtag('event', action, custom)
}
