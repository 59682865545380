import React, {Component} from 'react';
import style from './Navbar.module.scss';
import PropTypes from "prop-types";
import classNames from "classnames";
import Navigator from "../Navigator";
import Inspector from "../Inspector";
import Icon from "../Icon";
import {ICONS} from "../Icon/Icon";
import {isApp} from "../../utilities";
import COLORS from "../../styles/colors";
import {useHistory, withRouter} from "react-router-dom";

class Navbar extends Component {
	static propTypes = {
		left: PropTypes.node,
		center: PropTypes.node,
		right: PropTypes.node,
		color: PropTypes.string,
		fixed: PropTypes.bool,
	}

	static defaultProps = {
		fixed: false,
	}

	constructor(props) {
		super(props);

		this.scrollY = window.scrollY;

		this.state = {
			pullNav: 0,
		}
	}

	componentDidMount() {
		this.listenToScroll((distance) => {
			const fixed = this.props.fixed;
			this.setState({
				// pullNav: fixed ? false : window.scrollY > 100 ? 100 : window.scrollY,
				pullNav: fixed ? false : distance > 100 ? 100 : distance,
			})
		});

		this.unlisten = this.props.history.listen((location, action) => {
			this.setState({
				pullNav: 0,
			})
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
		document.removeEventListener('scroll', this.onScroll, true);
		this.unlisten();
	}

	onScroll = (callback, distance) => {
		if (!distance) {
			distance = window.scrollY
		}
		requestAnimationFrame(() => callback(distance))
	}

	listenToScroll = (callback) => {
		window.addEventListener('scroll', (e) => this.onScroll(callback, e.target.scrollTop));

		document.addEventListener('scroll', (e) => {
			if (e?.target?.className?.indexOf('scrollable') >= 0) {
				this.onScroll(callback, e.target.scrollTop)
			}
		}, true);
	}

	render() {
		const {left, center, right, color = null, zIndex = 8} = this.props;
		const {pullNav} = this.state;

		return (
			<div className={classNames(style.root, !isApp() && style.rootWeb, 'nav')} style={{color}}>
				<div
					className={classNames(style.bar, !isApp() && style.barWeb)}
					style={{
						color,
						transform: `translateY(${`-${pullNav * 2}px`})`,
						zIndex,
					}}
				>
					<div className={classNames(style.entry, style.left)}>
						{left}
					</div>
					<div className={classNames(style.entry, style.center)}>
						{center}
					</div>
					<div className={classNames(style.entry, style.right)}>
						{right}
					</div>
				</div>

			</div>
		);
	}
}

export function DefaultNavbar({left, ...props}) {
	return (
		<Navbar
			left={left ?? <Navigator/>}
			center={<Icon icon={ICONS.TomorrowLand}/>}
			right={<Inspector/>}
			{...props}
		/>
	)
}

export function BackNavbar({...props}) {
	const history = useHistory();

	return (
		<Navbar
			color={COLORS.CHAR}
			left={
				<div className={style.backButtonElevated} onClick={() => history.goBack()}>
					<Icon icon={ICONS.ArrowCircle} noOutline/>
				</div>
			}
			{...props}
		/>
	)
}

export default withRouter(Navbar);
