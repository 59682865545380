import COLORS from "./colors";
import SIZES from './sizes';
import {setCssVariable} from "../utilities";
import {DEFAULT_TRANSITION} from "../config";

/**
 * Sets the color config variables as CSS variables.
 */
const initColors = () => {
	Object.entries(COLORS).forEach(([name, value]) => {
		setCssVariable(name, value);
	})
}

/**
 * Sets the sizing config variables as CSS variables.
 */
const initSizes = () => {
	Object.entries(SIZES).forEach(([name, value]) => {
		setCssVariable(name, value);
	})
}

const initEase = () => {
	setCssVariable('EASE', `cubic-bezier(${DEFAULT_TRANSITION.ease})`);
	setCssVariable('DURATION', `${DEFAULT_TRANSITION.duration}s`);
}

export default function init() {
	initColors();
	initSizes();
	initEase();
}
