.card {
  border-radius: 16px;
  padding: 28px;
  overflow: hidden;
  background: white;
}

.noMargin {
  padding: 0;
}

.outline {
  background: transparent;
  border: 1.5px solid currentColor;
}
