import {setCssVariable} from "../utilities";

const COLORS = {
	ROSE: '#AC286E',
	WINE: '#7F0053',
	PLUM: '#800053',
	GREY: '#DDDDDD',
	PINK: '#FAECEC',
	MINT: '#DAF4E8',
	SAND: '#D8CCC5',
	CHAR: '#111111',
	IRON: '#777777',
	SWAN: '#ffffff',
	SOFT: '#FFDDDD',
	PURP: '#B9AEF6',
}

export default COLORS;

export function setBackgroundColor(color) {
	setCssVariable('bg-color', color);
}

export function setTextColor(color) {
	setCssVariable('text-color', color);
}
