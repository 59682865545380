.tag {
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 4px 6px 6px;
  display: inline-block;
  color: white;
  flex-grow: 0;
}

.big {
  padding: 6px 12px 8px;
  font-weight: 500;
  backdrop-filter: blur(11px);
}

