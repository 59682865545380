import React, {Component} from 'react';
import {ICONS} from "../Icon/Icon";
import Icon from "../Icon";
import Inspector from "./Inspector";
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar,
	triggerNavAction
} from "../NavController/NavController";
import {connect} from "react-redux";
import {navPropsSlice} from "../../store/navPropsSlice";
import {motion} from 'framer-motion';
import {viewPage} from "../../utilities";

class OnboardingContainer extends Component {
	state = {
		open: false,
		prevNav: null,
	}

	openOnboarding = () => {
		triggerNavAction(NAV_ACTIONS.ONBOARDING_OPENED);
		const prevNav = this.props.navProps.props;

		// set left nav item
		this.props.dispatch(navPropsSlice.actions.setLeft(NAV_ITEMS.CLOSE));
		this.props.dispatch(navPropsSlice.actions.setCenter(null));

		this.setState({
			open: true,
			prevNav,
		})

		this.handler = addNavActions({
			[NAV_ACTIONS.ON_LEFT]: this.closeOnboarding,
		});

		viewPage('/onboarding', 'onboarding');
	}

	closeOnboarding = () => {
		triggerNavAction(NAV_ACTIONS.ONBOARDING_CLOSED);
		const prev = this.state.prevNav;

		this.setState({
			open: false,
			prevNav: null,
		});

		setNavBar(this.props.dispatch, prev)
		removeNavActions(this.handler);
	}

	render() {
		const {open} = this.state;

		return (
			<>
				<motion.div
					animate={{scale: !open ? 1 : 0}}
				>
					<Icon icon={ICONS.Info} onClick={this.openOnboarding}/>
				</motion.div>

				<Inspector open={open}/>
			</>
		);
	}
}

export default connect(state => state)(OnboardingContainer);
