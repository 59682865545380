import {configureStore} from '@reduxjs/toolkit'
import totems from "./totemsSlice";
import navProps from "./navPropsSlice";
import beacon from "./beaconSlice";

export default configureStore({
	reducer: {
		totems,
		navProps,
		beacon,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck: false,
	})
})
