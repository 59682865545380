import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {beaconSlice} from "../store/beaconSlice";
import {debounce, throttle} from "lodash";

export default function BeaconListener() {
	const dispatch = useDispatch()

	const totemResponder = throttle(({detail}) => {
		dispatch(beaconSlice.actions.foundBeacon(detail.internalReference));
	}, 8 * 1_000, {
		leading: true,
		trailing: false,
	});

	useEffect(() => {
		window.addEventListener('detectTotem', totemResponder)
		return () => {
			window.removeEventListener('detectTotem', totemResponder);
		}
	}, []);

	return null;
}
