import {parse} from 'papaparse';
import designMiami from '/src/data/design_miami_content.csv';
import mdd from '/src/data/shaping_culture_mdd.csv';
import artWeek from '/src/data/art_week_top_20.csv';
import _ from "lodash";

export const rawDesignMiamiData = parse(designMiami, {
	header: true
});

export const rawArtWeekData = parse(artWeek, {
	header: true
});

export const rawMddData = parse(mdd, {
	header: true
});

const dataParser = (rawData) => {
	const grouped = {};

	_.get(rawData, 'data', []).forEach(({subcat_name, ...entry}) => {
		if (!subcat_name || typeof subcat_name !== "string") {
			return;
		}
		const categories = subcat_name?.split('&')?.map(s => s?.trim()) ?? [];

		const data = {
			title: entry.organization,
			description: entry.description,
			address: `${entry.address}${entry.address2 ? ` ${entry.address2}` : ``}, ${entry.city}, ${entry.state} ${entry.zipcode}`,
			image: entry.featured_image.toLowerCase() === 'n/a yet' ? null : entry.featured_image,
			categories
		};

		categories.forEach((category) => {
			grouped[category] = [..._.get(grouped, category, []), data];
		})
	})

	return grouped;
}

export const designMiamiData = () => {
	return dataParser(rawDesignMiamiData);
}

export const mddData = () => {
	return dataParser(rawMddData);
}

const convertGraphqlIdToUrl = (id) => {
	return `https://media.3njoy.net/resize=fit:clip,width:900/${id}`;
}

export const artWeekData = () => {
	return _
		.get(rawArtWeekData, 'data', [])
		.filter(({tagline}) => tagline !== '' && tagline)
		.map((entry) => ({
				title: entry.tagline,
				description: entry.description,
				venue: entry.venue === '' ? null : entry.venue,
				address: entry.address,
				image: entry.image_id === '' ? null : convertGraphqlIdToUrl(entry.image_id),
			})
		)
}
