import React, {Component} from 'react';
import classes from './Tag.module.scss';
import Typography from "../Typography/Typography";
import PropTypes from "prop-types";
import classNames from "classnames";

class Tag extends Component {

	static propTypes = {
		big: PropTypes.bool,
	}

	static defaultProps = {
		big: false,
	}

	render() {
		const {children, big} = this.props;

		return (
			<div className={classNames(classes.tag, big && classes.big)}>
				{children}
			</div>
		);
	}
}

export default Tag;
