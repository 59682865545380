import React, {Component} from 'react';
import PropTypes from "prop-types";
import classes from './Tabs.module.scss';
import {motion, AnimateSharedLayout, AnimatePresence} from 'framer-motion';
import {get} from 'lodash';
import classNames from "classnames";
import {DEFAULT_TRANSITION} from "../../config";
import Tappable from "../Tappable";

class Tabs extends Component {
	static propTypes = {
		tabs: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				component: PropTypes.node,
			})
		),
		horizontalScroll: PropTypes.bool,
	}

	state = {
		currentTab: 0,
		direction: 'next',
	}

	selectTab = (idx) => {
		this.setState((prev) => ({
			direction: idx > prev.currentTab ? 'next' : 'prev'
		}), () => {
			// gotta make sure the direction is set first because otherwise
			// it just picks the last direction for the animation
			this.setState({
				currentTab: idx,
			})
		})
	}

	render() {
		return (
			<div className={classes.root}>
				{this.renderTabs()}

				{this.renderBody()}
			</div>
		);
	}

	renderBody = () => {
		const {tabs} = this.props;
		const {currentTab, direction} = this.state;
		const tab = get(tabs, currentTab);
		// const move = direction === 'next' ? -40 : 40;
		const move = direction === 'next' ? -20 : 20;

		return (
			<AnimatePresence>
				<motion.div
					className={classes.content}
					key={currentTab}
					initial={{
						x: -move,
						opacity: 0,
						zIndex: 2,
					}}
					animate={{
						x: 0,
						opacity: 1,
						transition: {
							duration: .3,
							delay: .2,
						}
					}}
					exit={{
						x: move,
						opacity: 0,
						zIndex: 1,
					}}
					transition={{
						duration: .5,
						ease: DEFAULT_TRANSITION.ease,
					}}
				>
					{tab.component}
				</motion.div>
			</AnimatePresence>
		)
	}

	renderTabs = () => (
		<JustTabs
			horizontalScroll={this.props.horizontalScroll}
			tabs={this.props.tabs}
			tabClicked={this.selectTab}
			currentTabIndex={this.state.currentTab}
		/>
	);
}

export const JustTabs = (props) => {
	const {
		horizontalScroll,
		tabs,
		currentTabIndex,
		tabClassName = '',
		className = '',
		tabClicked = (i) => null
	} = props;

	return (
		<AnimateSharedLayout>
			<div className={classNames(classes.tabs, className, horizontalScroll && classes.horizontalScroll)}>
				{tabs.map((tab, idx) => {
					const isActive = idx === currentTabIndex;

					return (
						<Tappable
							key={idx}
							onClick={() => tabClicked(idx)}
							className={classNames(classes.tab, 'caps', isActive && classes.active, tabClassName)}
						>
							{tab.name}
							{
								isActive && (
									<motion.div
										className={classes.underline}
										layoutId={'underline'}
										transition={{...DEFAULT_TRANSITION, duration: .5}}
									/>
								)
							}
						</Tappable>
					)
				})}
			</div>
		</AnimateSharedLayout>
	);
}

export default Tabs;
