import {SWIFT_TRANSITION} from "../../config";

const transition = SWIFT_TRANSITION;

const preserveFixed = {
	// transitionEnd: {
	// 	transform: 'unset',
	// 	opacity: .7,
	// },
	// transform: 'unset',
}

const PAGE_TRANSITIONS = {
	leftRight: {
		initial: {
			x: '100%',
		},
		animate: {
			x: '0%',
			...preserveFixed,
		},
		exit: {
			x: '100%',
		},
		transition,
	},
	fadeLeft: {
		initial: {
			x: '0%',
			opacity: 1,
		},
		animate: {
			x: '0%',
			opacity: 1,
			transition: {
				duration: .1,
			}
		},
		exit: {
			x: '-100%',
			opacity: 1,
		},
		transition,
	},
	leftLeft: {
		initial: {
			x: '100%',
		},
		animate: {
			x: '0%',
			...preserveFixed,
		},
		exit: {
			x: '-100%',
		},
		transition,
	},
	rightLeft: {
		initial: {
			x: '-100%',
		},
		animate: {
			x: '0%',
			...preserveFixed,
		},
		exit: {
			x: '-100%',
		},
		transition,
	},
	rightRight: {
		initial: {
			x: '-100%',
		},
		animate: {
			x: '0%',
			...preserveFixed,
		},
		exit: {
			x: '100%',
		},
		transition,
	},
	upDown: {
		initial: {
			y: '100%',
		},
		animate: {
			y: '0%',
			...preserveFixed,
		},
		exit: {
			y: '100%',
		},
		transition: SWIFT_TRANSITION,
	},
	fade: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			...preserveFixed,
		},
		exit: {
			opacity: 0,
		},
		transition,
	},
	dip: {
		initial: {
			scale: 0.7,
		},
		animate: {
			scale: 1,
			...preserveFixed,
		},
		exit: {
			scale: 0.7,
		},
		transition
	},
	none: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			...preserveFixed,
		},
		exit: {
			opacity: 0,
		},
		transition: {
			duration: .1,
		},
	}
};

export default PAGE_TRANSITIONS;
