.root {
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  background: var(--WINE);
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
}

.entries {
  margin: 20vh 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.entry {
  font-size: 48px;
  line-height: 90%;
  margin-top: 24px;
  position: relative;
  color: var(--SAND);
  text-decoration: none;
}

.activeLine {
  &:after {
	position: absolute;
	content: '';
	height: 2px;
	background: var(--SAND);
	width: calc(100% + 2px);
	left: -1px;
	bottom: -2px;
  }
}

.close {
  //
}
