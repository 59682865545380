import React, {Component} from 'react';
import PropTypes from "prop-types";
import classes from './Page.module.scss';
import COLORS, {setBackgroundColor, setTextColor} from "../../styles/colors";
import classNames from "classnames";
import {motion} from 'framer-motion';
import PAGE_TRANSITIONS from "./PageTransitions";

class Page extends Component {
	state = {
		animating: false,
	}

	static propTypes = {
		children: PropTypes.node,
		backgroundColor: PropTypes.oneOf(Object.values(COLORS)),
		textColor: PropTypes.oneOf(Object.values(COLORS)),
		className: PropTypes.string,
		transition: PropTypes.oneOf([
			'up-down',
			'left-right',
			'left-left',
			'right-left',
			'fade-left',
			'fade',
			'dip',
			'none',
		]),
	}

	static defaultProps = {
		backgroundColor: COLORS.ROSE,
		textColor: COLORS.SWAN,
		transition: 'none',
	}

	componentDidMount() {
		const {backgroundColor, textColor, image} = this.props;

		setBackgroundColor(backgroundColor);
		setTextColor(textColor);
	}

	getTransition = () => {
		const {transition} = this.props;

		switch (transition) {
			case 'up-down':
				return PAGE_TRANSITIONS.upDown;
			case 'left-right':
				return PAGE_TRANSITIONS.leftRight;
			case 'left-left':
				return PAGE_TRANSITIONS.leftLeft;
			case 'fade-left':
				return PAGE_TRANSITIONS.fadeLeft;
			case 'right-left':
				return PAGE_TRANSITIONS.rightLeft;
			case 'right-right':
				return PAGE_TRANSITIONS.rightRight;
			case 'fade':
				return PAGE_TRANSITIONS.fade;
			case 'dip':
				return PAGE_TRANSITIONS.dip;
			case 'none':
				return PAGE_TRANSITIONS.none;
		}
	}

	completeAnimation = () => {
		this.setState({
			animating: false,
		})
	}

	startAnimation = () => {
		this.setState({
			animating: true,
		})
	}

	render() {
		const {children, nav, className = '', image, rootClassName = ''} = this.props;
		const transition = this.getTransition();
		const {animating} = this.state;

		return (

			<motion.div
				className={classNames(classes.rootContainer, className, 'scrollable')}
				onAnimationComplete={this.completeAnimation}
				onAnimationStart={this.startAnimation}
				{...transition}
			>
				<div className={classNames(classes.root, rootClassName)}>
					{/*{nav}*/}
					<div className={classes.navPadding} />
					{children}
				</div>
			</motion.div>

		);
	}
}

export default Page;
