import React, {Component} from 'react';
import {isApp, toCachedUrl, viewPage} from "../../utilities";
import Navbar, {DefaultNavbar} from "../../components/Navbar/Navbar";
import {ICONS} from "../../components/Icon/Icon";
import Icon from "../../components/Icon";
import Page from "../../components/Page/Page";
import Typography from "../../components/Typography/Typography";
import {withRouter} from "react-router-dom";
import {get} from 'lodash';
import classes from './LivingShapes.module.scss';
import Card from "../../components/Card/Card";
import DownloadApp from "../../components/DownloadApp";
import {connect} from "react-redux";
import format from 'date-fns/format'
import COLORS from "../../styles/colors";
import Button from "../../components/Button";
import Tag from "../../components/Tag";
import Inspector from "../../components/Inspector";
import classNames from "classnames";
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar
} from "../../components/NavController/NavController";

class LivingShapes extends Component {
	getId = () => Number(get(this.props.match, 'params.id', 'unknown'))

	state = {
		totem: null,
		onboardingOpen: false,
	}

	getTotem = () =>
		this.props.totems.find(({internalReference}) =>
			internalReference === this.getId()
		)

	getAuctionDate = () => {
		const {auctionedAt} = this.getTotem();

		if (!auctionedAt) {
			return 'TBD';
		}

		return format(new Date(auctionedAt), 'LLLL do y')
	}

	isLuminaries = () => this.getTotem().isLuminaries;

	componentDidMount() {
		if (isApp()) {
			this.unlockShapes();
			setNavBar(this.props.dispatch, {
				left: NAV_ITEMS.CLOSE,
				center: null,
			})
		} else {
			setNavBar(this.props.dispatch, {
				left: null,
			})
		}

		this.attemptToOpenInApp();

		this.handle = addNavActions({
			[NAV_ACTIONS.ON_LEFT]: this.goBack
		})
	}

	componentWillUnmount() {
		removeNavActions(this.handle);
	}

	renderAppNav = () => {
		const left = this.state.onboardingOpen ? false : this.isBack() ? (
			<Icon onClick={this.goBack} icon={ICONS.Close}/>) : undefined

		return (
			<DefaultNavbar
				left={left}
				right={<Inspector onOpen={this.onboardingOpen} onClose={this.onboardingClose}/>}
			/>
		)
	}

	attemptToOpenInApp = () => {
		if (isApp()) {
			return;
		}

		location.href = `probamdd://livingshapes?shapeID=${this.getId()}&type=luminaries`;
	}

	onboardingOpen = () => {
		this.setState({
			onboardingOpen: true,
		})
	}

	onboardingClose = () => {
		this.setState({
			onboardingOpen: false,
		})
	}

	goBack = () => {
		const {history} = this.props;

		if (history.action !== 'POP') {
			history.goBack();
			return;
		}

		history.push('/');
	}

	openBuilder = () => {
		this.props.history.push('/builder-landing');
	}

	openViewer = () => {
		const path = `startAR?totemId=${this.getTotem().internalReference}&type=luminaries`;
		viewPage(`/${path}`, path);
		window.location.href = `uniwebview://${path}`
	}

	unlockShapes = () => {
		if (!this.fromBeacon()) {
			return;
		}

		const shapes = get(this.getTotem(), 'shapes', [])
			.map(({shapeId = null}) => shapeId)
			.filter(i => i !== null);

		if (shapes.length === 0) {
			return;
		}

		window.location.href = `uniwebview://shapeUnlock?shapes=${shapes.join(',')}`
	}

	hasAuctionDate = () => !!this.getTotem().auctionedAt

	isBack = () => {
		return this.props.location.search.indexOf('allowBack') >= 0;
	}

	fromBeacon = () => this.props.location.search.indexOf('fromBeacon') >= 0

	getShapes = () => get(this.getTotem(), 'shapes')

	render() {
		const totem = this.getTotem();
		const {onboardingOpen} = this.state;

		if (!totem) {
			return null;
		}

		return (
			<Page
				backgroundColor={COLORS.SWAN}
				transition={this.isBack() ? 'up-down' : 'none'}
				className={classes.root}
			>
				<div className={classes.background}>
					{/*<div*/}
					{/*	className={classes.bgImage}*/}
					{/*	style={{backgroundImage: `url(${toCachedUrl(get(totem, 'hqImage.url'))})`}}*/}
					{/*/>*/}

					<img className={classes.bgImageTest} src={toCachedUrl(get(totem, 'hqImage.url'))} alt=""/>
					<div className={classes.bgGradient}/>
					<div className={classes.bgGradientContinued}/>

					{/*<div className={classes.overlayFade}/>*/}
					<div className={classes.overlay}/>
				</div>

				<div className={classNames(classes.wrap, onboardingOpen && classes.hideText)}>
					<Typography className={classNames(classes.title, totem.isLuminaries && classes.titleLuminaries)} type={'h2'}>
						{
							this.isLuminaries() ? (
								<>
									Living Shape <br/>
									by {this.getTotem()?.artistName}
								</>
							) : (
								<>
									Living Shapes <br/>
									No. {totem.internalReference}
								</>
							)
						}
					</Typography>

					{
						this.fromBeacon() && (
							<div className={classes.unlockTag}>
								<Tag big>
									Unlocked
								</Tag>
							</div>
						)
					}

					<div className={classNames(classes.content, this.isLuminaries() && classes.luminariesContent)}>
						{
							this.isLuminaries() ? (
								<Card>
									<Typography type={'p'} className={classes.details}>
										<div style={{whiteSpace: 'pre'}}
											 dangerouslySetInnerHTML={{__html: totem.subTitle}}/>
									</Typography>
									<Button
										small
										type={'outlined'}
										color={'gray'}
										className={classes.viewInAr}
										onClick={this.openViewer}
									>
										View In AR
									</Button>
								</Card>
							) : (
								<Card>
									<Typography type={'p'} className={classes.location}
												dangerouslySetInnerHTML={{__html: totem.location}}/>
									<Typography type={'p'} className={classes.details}>
										Designed by Studio Proba, 2021 <br/>
										{
											this.hasAuctionDate() && (
												<>
													Auction Date: {this.getAuctionDate()}
												</>
											)
										}
									</Typography>
								</Card>
							)
						}
					</div>
					{
						!this.isLuminaries() && this.renderUnlockedShapes()
					}

					{this.renderAppTout()}
				</div>
			</Page>
		);
	}

	renderUnlockedShapes = () => {
		if (!isApp()) {
			return null;
		}

		const shapes = this.getShapes();

		return (
			<div className={classes.unlockedShapes}>
				<Card>
					{
						this.fromBeacon() ? (
							<Typography type={'p'} className={classes.shapes}>
								You've unlocked {shapes.length} shapes!
							</Typography>
						) : (
							<Typography type={'p'} className={classes.shapes}>
								Shapes
							</Typography>
						)
					}

					<div className={classes.unlockedShapesShapes}>
						{
							shapes.map((shape, i) => (
								<div key={i}>
									<img src={toCachedUrl(get(shape, 'primaryImage.url'))}/>
								</div>
							))
						}
					</div>
					{
						this.fromBeacon() ? (
							<Button
								small
								type={'outlined'}
								color={'gray'}
								className={classes.buildYourOwn}
								onClick={this.openBuilder}
							>
								Build Your Own
							</Button>
						) : (
							<Typography type={'p'} className={classes.unlockedShapesDescription}>
								Go find the sculpture to unlock the shapes!
							</Typography>
						)
					}

				</Card>
			</div>
		)
	}

	renderAppTout = () => {
		if (isApp()) {
			return null;
		}

		return (
			<DownloadApp/>
		);
	}
}

const mapStateToProp = state => {
	const totems = state.totems?.totems?.totems ?? [];
	return {totems};
}

export default connect(mapStateToProp)(withRouter(LivingShapes));
