import {get} from "lodash";

const Places = [
	{
		name: '001',
		location: 'Paradise Plaza',
		y: 650,
		x: 696,
	},
	{
		name: '002',
		location: 'Paradise Plaza',
		y: 434,
		x: 696,
	},
	{
		name: '003',
		location: 'Paradise Plaza',
		y: 434,
		x: 696,
	}
];

export default Places;

export const toLocations = (totems, isLuminaries = null) => {
	let locations = {};

	totems.filter((place) => {
		if (isLuminaries === null) {
			return true;
		}
		return place.isLuminaries === isLuminaries
	}).forEach((place) => {
		const locationKey = `${place.coordinatesLeft}x${place.coordinatesTop}`;
		locations[locationKey] = {
			x: place.coordinatesLeft,
			y: place.coordinatesTop,
			places: [place, ...get(locations, `${locationKey}.places`, [])]
		};
	})

	return locations;
}

export const getLocations = () => {
	let locations = {};

	Places.forEach((place) => {
		const locationKey = `${place.x}x${place.y}`;
		locations[locationKey] = {
			x: place.x,
			y: place.y,
			places: [place, ...get(locations, `${locationKey}.places`, [])]
		};
	})

	return locations;
}
