import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {totems} from "../api";

export const fetchTotems = createAsyncThunk('totems/fetch', async (thunkAPI) => {
	const response = await totems();
	return response.data;
});

export const totemsSlice = createSlice({
	name: 'totems',
	initialState: {
		totems: {},
		loading: 'idle',
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTotems.fulfilled, (state, action) => {
			state.totems = action.payload;
		})
	}
})

// Action creators are generated for each case reducer function
export const {} = totemsSlice.actions

export default totemsSlice.reducer
