import React, {Component} from 'react';
import classes from './Tappable.module.scss';
import classNames from "classnames";
import PropTypes from "prop-types";

class Tappable extends Component {
	static propTypes = {
		component: PropTypes.oneOf(['div', 'p', 'h1', 'h2', 'h3', 'span', 'button']),
		children: PropTypes.node,
		onClick: PropTypes.func,
		className: PropTypes.string,
	}

	static defaultProps = {
		component: 'div',
		onClick: () => null
	}

	render() {
		const {component: Component, children, onClick, className, ...other} = this.props;

		return (
			<Component onClick={onClick} className={classNames(classes.component, className)} {...other}>
				{children}
			</Component>
		);
	}
}

export default Tappable;
