.root {
  z-index: 2;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100vw;
  height: 152px;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  color: var(--WINE);

  display: none;
}

.tabs {
  position: absolute;
  bottom: 0;
  width: 70vw;
  left: 15vw;
  margin-bottom: 0;
}

.tab {
  width: 50% !important;
}

.map {
  position: absolute;
  z-index: 1;

  img {
	width: 100%;
  }

  .lowRes {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	filter: blur(5px);
  }
}

.place {
  position: absolute;
  background-color: var(--GREY);
  border-radius: 50%;
  font-size: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2.5px;
  border-style: solid;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.placeCounter {
  position: absolute;
  bottom: -10px;
  right: -10px;
  color: white;
  background: var(--ROSE);
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.selectedTray {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 160px;
  z-index: 4;
  display: flex;
  scroll-behavior: smooth;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
	display: none;
  }
}

.trayElementContainer {
  scroll-snap-align: center;
  flex-shrink: 0;
  padding: 0 0 0 22px;
  box-sizing: border-box;
  color: black;

  &:last-child {
	padding-right: 22px;
  }

  .trayElement {
	overflow: hidden;
	display: flex;
	width: 100%;
	height: 88px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	background: white;
	border-radius: 8px;
  }

  .trayElementImage {
	width: 88px;
	height: 88px;
	background-color: #ccc;
	background-position: center;
	background-size: cover;
  }

  .trayElementContent {
	padding: 20px
  }

  .trayElementTitle {
	font-weight: 500;
  }

  .trayElementLocation {
	font-size: 28px;
  }
}
