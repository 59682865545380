import React, {Component} from 'react';
import COLORS from "../../styles/colors";
import classes from "./Home.module.scss";
import {DefaultNavbar} from "../../components/Navbar/Navbar";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button";
import Page from "../../components/Page/Page";
import {withRouter} from "react-router-dom";
import BeaconOverlay from "../../components/BeaconOverlay";
import Inspector from "../../components/Inspector";
import classNames from "classnames";
import Navigator from "../../components/Navigator/Navigator";
import {motion} from 'framer-motion';
import Icon from "../../components/Icon";
import {ICONS} from "../../components/Icon/Icon";
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar
} from "../../components/NavController/NavController";
import {connect} from "react-redux";
import {viewPage} from "../../utilities";

class Home extends Component {
	state = {
		onboardingOpen: true,
	}

	componentDidMount() {
		setNavBar(this.props.dispatch, {
			left: NAV_ITEMS.MENU,
			right: NAV_ITEMS.INFO,
		})

		this.handler = addNavActions({
			[NAV_ACTIONS.ONBOARDING_OPENED]: this.onboardingOpen,
			[NAV_ACTIONS.ONBOARDING_CLOSED]: this.onboardingClose,
		});

		setTimeout(() => {
			this.setState({
				onboardingOpen: false,
			})
		}, 200)

		document.querySelector('body').style.minHeight = 'unset';
		document.querySelector('body').style.overflowY = 'hidden';
	}

	componentWillUnmount() {
		removeNavActions(this.handler);
		document.querySelector('body').style.minHeight = '100vh';
		document.querySelector('body').style.overflowY = 'auto';
	}

	goTo = (path) => () => {
		this.props.history.push(path);
	}

	launch = (path) => () => {
		viewPage(`/${path}`, path);
		window.location.href = `uniwebview://${path}`
	}

	onboardingOpen = () => {
		this.setState({
			onboardingOpen: true,
		})
	}

	onboardingClose = () => {
		this.setState({
			onboardingOpen: false,
		})
	}

	render() {
		const {onboardingOpen} = this.state;

		return (
			<Page
				backgroundColor={COLORS.PINK}
				textColor={COLORS.PLUM}
				className={classes.landing}
				transition={'dip'}
			>

				<div className={classNames(classes.root, onboardingOpen && classes.hideText)}>
					<BeaconOverlay/>

					<Typography className={classes.title} type={'h1'}>
						Welcome
					</Typography>
					<Typography className={classes.paragraph} type={'p'}>
						Tomorrow Land is an exploration of the<br/>
						Miami Design District through the art<br/>
						of Studio Proba
					</Typography>

					<Button
						small
						type={'outlined'}
						color={'none'}
						className={classes.button}
						onClick={this.goTo('/about')}
					>
						About
					</Button>


					{this.renderButtons()}
				</div>
			</Page>
		);
	}

	renderButtons = () => (
		<div className={classes.buttons}>
			<MapButton onClick={this.goTo('/map')}/>
			<ViewButton onClick={this.launch('startAR')}/>
			<BuildYourOwnButton onClick={this.goTo('/builder-landing')}/>
		</div>
	)
}

const BuildYourOwnButton = ({onClick}) => {
	return (
		<>
			<Typography type={'h3'} className={classes.buildYourOwnButtonText}>
				Build your own
			</Typography>

			<div className={classes.buildYourOwnButton} onClick={onClick}>
				<svg width="368" height="165" viewBox="0 0 368 165" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M274.433 39.6546C264.119 27.9339 231.288 22.0971 202.411 19.7907C174.496 17.5469 148.352 7.86598 121.623 0.962225C119.224 0.176983 116.669 -0.133262 114.129 0.0521576C110.845 0.491488 108.026 2.45277 105.379 4.3513C79.9226 22.5678 40.2505 42.0082 24.5913 67.6777C10.6339 90.413 -3.59852 125.732 -9.44276 151.182C-15.837 179.111 -11.1617 209.236 -12.6571 238.389C-14.7885 279.042 -15.2354 320.073 -21.4922 360.162C-26.6489 393.347 -41.8611 425.449 -17.8482 457.959C-8.56612 470.512 2.88171 481.997 10.9089 495.146C19.5034 509.189 28.0979 510.632 43.5679 504.717C99.9133 483.252 157.238 463.828 213.446 442.097C223.502 438.205 231.477 427.975 237.906 419.11C253.084 398.211 264.48 374.722 281.48 355.266C323.834 306.861 391.833 215.434 359.604 155.214C353.295 143.462 342.999 134.048 333.906 123.99C319.124 107.641 307.281 89.2205 295.506 70.8942C286.912 57.5417 281.171 47.3272 274.433 39.6546Z"
						fill="#8F8546"/>
				</svg>
			</div>
		</>
	)
}

const ViewButton = ({onClick}) => {
	return (
		<div className={classes.viewButton} onClick={onClick}>
			<Typography type={'h3'} className={classes.viewButtonText}>
				View
			</Typography>
			<svg width="201" height="185" viewBox="0 0 201 185" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M149.252 10.2445C130.743 -0.741385 117.882 -2.98565 96.8635 3.96686L55.4391 17.8091C48.9455 19.9749 42.3734 22.1879 36.617 25.8289C18.8928 37.0659 12.6972 63.4948 8.28967 83.6461C5.73822 95.3121 3.33319 106.999 1.07453 118.707C0.211848 123.195 -0.635148 127.872 0.682402 132.267C1.99995 136.661 5.23108 140.114 8.66612 143.252C21.7632 154.929 38.5619 161.756 55.2666 167.563C82.1509 176.979 110.164 184.246 138.57 184.826C154.428 177.105 167.666 165.993 173.909 153.94C190.41 122.191 194.221 100.58 200.573 57.0132C201.232 53.3251 154.302 13.2578 149.252 10.2445Z" fill="#B9AEF6"/>
			</svg>
		</div>
	)
}

const MapButton = ({onClick}) => {
	return (
		<div className={classes.mapButton} onClick={onClick}>
			<Typography type={'h3'} className={classes.mapButtonText}>
				Map
			</Typography>

			<svg width="215" height="192" viewBox="0 0 215 192" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M98.2505 182.445C80.0401 176.811 62.065 169.796 46.2544 159.155C30.4438 148.515 16.8134 134.045 9.66101 116.31C4.42219 103.378 2.80662 89.3005 1.23811 75.5053C-0.4402 60.5331 -1.77343 44.1171 6.82201 31.703C15.1508 19.744 30.3497 15.1456 44.4663 11.6301C57.4692 8.38141 70.5193 5.35251 83.5693 2.38631C90.6589 0.816897 97.9839 -0.862397 105.105 0.502995C110.752 1.5702 115.865 4.4579 120.79 7.43979C134.075 15.4124 146.623 24.5464 159.172 33.6648C173.586 44.1641 188.252 54.9303 198.62 69.4317C206.159 80.1866 211.102 92.5444 213.062 105.534C215.021 118.523 213.943 131.791 209.913 144.293C204.329 161.148 193.742 183.748 175.264 188.754C150.106 195.534 122.515 189.963 98.2505 182.445Z" fill="#751651"/>
			</svg>
		</div>

	)
}

export default connect()(withRouter(Home));
