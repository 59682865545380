import {createSlice} from '@reduxjs/toolkit'
import {triggerBeacon} from "../utilities";

export const BEACON_STYLE = {
	MAXIMIZED: 'maximized',
	MINIMIZED: 'minimized',
	CLOSED: 'closed',
}

export const beaconSlice = createSlice({
	name: 'beacon',
	initialState: {
		totemId: null,
		style: BEACON_STYLE.MAXIMIZED,
	},
	reducers: {
		foundBeacon(state, {payload}) {
			if (state.totemId === payload) {
				return;
			}

			state.totemId = payload;
			state.style = BEACON_STYLE.MAXIMIZED;
			triggerBeacon(payload);
		},
		minimize(state) {
			state.style = BEACON_STYLE.MINIMIZED;
		},
		maximize(state) {
			state.style = BEACON_STYLE.MAXIMIZED;
		},
		close(state) {
			state.style = BEACON_STYLE.CLOSED;
		},
	},
})

// Action creators are generated for each case reducer function
export const {} = beaconSlice.actions

export default beaconSlice.reducer
