import React, {Component, useState} from "react";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion";
import {DEFAULT_TRANSITION, FAST_TRANSITION, SWIFT_TRANSITION} from "../../config";
import Card from "../../components/Card/Card";
import classes from "./DesignGuide.module.scss";
import Typography from "../../components/Typography/Typography";
import Chevron from "../../components/Chevron";
import {shuffle} from "lodash";
import PropTypes from "prop-types";
import Tappable from "../../components/Tappable";
import classNames from "classnames";
import _ from "lodash";

export class ContentCard extends Component {
	static propTypes = {
		title: PropTypes.string,
		body: PropTypes.node,
		image: PropTypes.string,
		idx: PropTypes.number,
	}

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		}
	}

	toggleDirection = () => {
		this.setState(({open}) => ({open: !open}))
	}

	render() {
		const {title, body, phone, image, address, idx} = this.props;
		const {open} = this.state;

		return (
			<div>
				<Card noMargin className={classes.card}>
					<AnimatePresence initial={false}>
						{
							open ? (
								<motion.div
									key={`top-open-${idx}`}
									className={classes.cardTopOpen}
									transition={SWIFT_TRANSITION}
								>
									{
										image ?
											<motion.img
												src={image}
												key={`image-open-${idx}`}
												className={classes.cardImage}
												transition={SWIFT_TRANSITION}
												initial={{
													height: '0px',
												}}
												animate={{
													height: '226px',
													transition: {
														delay: .4,
														...SWIFT_TRANSITION
													}
												}}
												exit={{
													height: '0px',
												}}
											/>
											: <div className={classes.imageSubstitute} style={{height: '28px'}} />
									}

									<motion.div
										initial={{opacity: 0}}
										animate={{opacity: 1, transition: {delay: .4, ease: DEFAULT_TRANSITION.ease}}}
										exit={{opacity: 0}}
										key={`title-open-${idx}`}
										transition={SWIFT_TRANSITION}
									>
										<Typography
											className={classes.cardTitle}
											type={'h4'}
											dangerouslySetInnerHTML={{__html: _.truncate(title, {length: 200})}}
										/>
									</motion.div>
								</motion.div>
							) : (
								<motion.div
									className={classes.cardTopClosed}
									transition={SWIFT_TRANSITION}
									key={`top-closed-${idx}`}
									exit={{
										position: 'absolute',
										height: '72px'
									}}
								>
									{
										image && <motion.img
											src={image}
											key={`image-closed-${idx}`}
											className={classes.cardImage}
											transition={SWIFT_TRANSITION}
											initial={{
												x: '-80px',
												opacity: 0,
											}}
											animate={{
												x: '0px',
												opacity: 1,
												transition: {
													delay: 1,
													ease: DEFAULT_TRANSITION.ease
												}
											}}
											exit={{
												x: '-80px',
												opacity: 0,
											}}
										/>
									}
									<motion.div
										initial={{opacity: 0}}
										animate={{opacity: 1, transition: {delay: .6, ease: DEFAULT_TRANSITION.ease}}}
										exit={{opacity: 0}}
										key={`title-closed-${idx}`}
										transition={SWIFT_TRANSITION}
									>
										<Typography
											className={classes.cardTitle}
											type={'h4'}
											dangerouslySetInnerHTML={{__html: _.truncate(title, {length: 30})}}
										/>
									</motion.div>
								</motion.div>
							)
						}
					</AnimatePresence>

					<Typography className={classes.cardContent} type={'p'}>
						<motion.div
							key={`content-${idx}`}
							animate={{
								height: open ? 'auto' : '100px'
							}}
							className={classNames(classes.cardContentBody, open ? classes.cardContentVisible : classes.cardContentHidden)}
						>
							{body}
						</motion.div>
						{/*<AnimatePresence>*/}
						{/*	{*/}
						{/*		open && (*/}
						{/*			<motion.div*/}
						{/*				key={`key-value-${idx}`}*/}
						{/*				transition={FAST_TRANSITION}*/}
						{/*				layout*/}
						{/*				initial={{*/}
						{/*					opacity: 0,*/}
						{/*					height: 0,*/}
						{/*				}}*/}
						{/*				animate={{*/}
						{/*					opacity: 1,*/}
						{/*					height: 'auto',*/}
						{/*				}}*/}
						{/*				exit={{*/}
						{/*					opacity: 0,*/}
						{/*					height: 0,*/}
						{/*				}}*/}
						{/*			>*/}
						{/*				{*/}

						{/*					)*/}
						{/*				}*/}

						{/*				{*/}

						{/*			</motion.div>*/}
						{/*		)*/}
						{/*	}*/}
						{/*</AnimatePresence>*/}
					</Typography>

					<Tappable className={classes.chevron} onClick={this.toggleDirection}>
						<Chevron direction={!open ? 'up' : 'down'}/>
					</Tappable>
				</Card>
			</div>
		)
	}
}
