import React, {Component, useEffect} from 'react';
import {PortalWithState} from "react-portal";
import classes from './Navigator.module.scss';
import {Link, withRouter} from "react-router-dom";
import classNames from "classnames";
import Icon from "../Icon";
import {ICONS} from "../Icon/Icon";
import Navbar from "../Navbar";
import COLORS from "../../styles/colors";
import {AnimatePresence, motion} from "framer-motion";
import {DEFAULT_TRANSITION, SWIFT_TRANSITION} from "../../config";

const NAV_ITEMS = {
	'Tomorrow Land': '/',
	'Luminaries': '/luminaries',
	'Design Guide': '/design-guide',
	'About': '/about',
}

class Navigator extends Component {
	render() {
		return (
			<PortalWithState>
				{({openPortal, closePortal, isOpen, portal}) => (
					<React.Fragment>
						<Icon icon={ICONS.Hamburger} onClick={openPortal}/>
						<AnimatePresence>
							{portal(this.renderPortal(closePortal))}
						</AnimatePresence>
					</React.Fragment>
				)}
			</PortalWithState>
		);
	}

	renderPortal = (closePortal) => (
		<motion.div
			className={classes.modal}
			initial={{
				x: '-100%',
			}}
			animate={{
				x: 0
			}}
			exit={{
				x: '-100%',
			}}
			transition={SWIFT_TRANSITION}
		>
			<Navbar
				color={COLORS.SAND}
				left={<Icon className={classes.close} icon={ICONS.Close} onClick={closePortal}/>}
			/>
			<div className={classes.entries}>
				{Object.entries(NAV_ITEMS).map((item) => this.renderEntry(item, closePortal))}
			</div>
			<Lockit />
		</motion.div>
	)

	renderEntry = ([name, link], closePortal) => {
		// const isActive = this.props.match.path === link;
		const isActive = document.location.pathname === link;

		return (
			<Link to={link} onClick={closePortal} key={link}
				  className={classNames(classes.entry, 'ivar', isActive ? classes.activeLine : null)}>
				{name}
			</Link>
		)
	}
}

export const Lockit = () => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'auto';
		}
	});
	return null;
}

export default withRouter(Navigator);
