import React, {Component} from 'react';
import Card from "../Card/Card";
import classes from './DownloadApp.module.scss';
import Typography from "../Typography/Typography";

const IMAGES = {
	appStore: new URL('/src/assets/images/web-app-store.png?width=200&quality=75&as=webp', import.meta.url),
	googlePlay: new URL('/src/assets/images/web-google-play.png?width=200&quality=75&as=webp', import.meta.url),
	androidBeta: new URL('/src/assets/images/web-google-beta.png?width=200&quality=75&as=webp', import.meta.url)
}

class DownloadApp extends Component {
	openAppStore = () => {
		window.location = 'https://apps.apple.com/us/app/tomorrow-land/id1591249072';
	}

	downloadApk = () => {
		window.open("https://s3.us-west-2.amazonaws.com/tomorrowland.miami/TomorrowLandBeta1.1.apk");
	}

	openGooglePlay = () => {
		window.open("https://play.google.com/store/apps/details?id=com.enjoy.tomorrowland");
	}

	render() {
		return (
			<Card className={classes.downloadApp}>
				<Typography type={'h4'} className={classes.title}>
					Explore Tomorrow Land
				</Typography>
				<Typography className={classes.description}>
					Download the app to build your own Living Shapes
				</Typography>

				<div className={classes.downloadLinks}>
					<img onClick={this.openAppStore} src={IMAGES.appStore}/>
					<img onClick={this.openGooglePlay} src={IMAGES.googlePlay}/>
				</div>
			</Card>
		);
	}
}

export default DownloadApp;
