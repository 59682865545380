import React, {Component} from 'react';
import Navbar from "../../components/Navbar";
import Icon from "../../components/Icon";
import {ICONS} from "../../components/Icon/Icon";
import Typography from "../../components/Typography/Typography";
import Page from "../../components/Page/Page";
import classes from './Web.module.scss';
import {Portal} from "react-portal";
import COLORS from "../../styles/colors";
import DownloadApp from "../../components/DownloadApp";
import {Link} from "react-router-dom";
import {setNavBar} from "../../components/NavController/NavController";
import {connect} from "react-redux";

// const bgImage = new URL('/src/assets/images/web-background1.png?width=1200&quality=75&as=webp', import.meta.url);
const bgImage = new URL('/src/assets/images/web-background2.png?width=1200&quality=75&as=webp', import.meta.url);

class Web extends Component {
	componentDidMount() {
		setNavBar(this.props.dispatch, {
			left: null,
			right: null,
			fixed: false,
		})
	}

	render() {
		return (
			<Page
				className={classes.web}
				rootClassName={classes.webRoot}
				backgroundColor={COLORS.WINE}
			>
				<div className={classes.content}>
					<div>
						{/*<Typography type={'caps'} className={classes.comingSoon}>*/}
						{/*	Coming Soon*/}
						{/*</Typography>*/}
						<Typography type={'h3'} className={classes.title}>
							A participatory art installation by Studio Proba × Enjoy the Weather
						</Typography>
					</div>

					{this.renderLogos()}

					<div className={classes.download}>
					<DownloadApp/>
					</div>
				</div>

				{this.renderBackgroundImage()}
			</Page>
		);
	}

	renderLogos = () => (
		<div className={classes.logos}>
			<div className={classes.logoContainer}>
				<Icon icon={ICONS.MDD} height={38}/>
			</div>

			<div className={classes.divider}/>

			<div className={classes.logoContainer}>
				<Icon icon={ICONS.DesignMiami} height={41}/>
			</div>
		</div>
	)

	renderBackgroundImage = () => (
		<Portal>
			<div
				className={classes.backgroundImage}
				style={{
					backgroundImage: `url(${bgImage.toString()})`
				}}
			>
			</div>
		</Portal>
	)
}

export default connect()(Web);
