import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Link, Route, Switch, useLocation} from "react-router-dom";
import Landing from "./pages/Landing";
import About from "./pages/About";
import 'normalize.css';
import './index.scss';
import init from "./styles/init";
import DesignGuide from "./pages/DesignGuide";
import Map from "./pages/Map/Map";
import Onboarding from "./pages/Onboarding";
import LivingShapes from "./pages/LivingShapes";
import BuilderLanding from "./pages/BuilderLanding";
import Home from "./pages/Home/Home";
import Web from "./pages/Web";
import {Provider} from "react-redux";
import store from './store/store';
import DataLoader from "./DataLoader/DataLoader";
import {isApp} from "./utilities";
import {AnimatePresence} from "framer-motion";
import NavController from "./components/NavController";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import BeaconListener from "./BeaconListener";
import Luminaries from "./pages/Luminaries/Luminaries";

init();

Sentry.init({
	dsn: "https://fd15c88afd124978bd800f7509c13a20@o926465.ingest.sentry.io/6078129",
	integrations: [
		new Integrations.BrowserTracing(),
		new CaptureConsoleIntegration({
			levels: ['error', 'warn'],
		})
	],
	tracesSampleRate: 1.0,
	environment: process.env.NODE_ENV,
	release: window?.APP_VERSION ?? 'vX.X.X',
});


function AppShell() {
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (isApp()) {
			window.location.href = `uniwebview://pageReady`;
		}
	}, []);

	return (
		<Provider store={store}>
			<DataLoader
				onLoaded={() => setReady(true)}
			/>
			{
				ready && (
					<Router>
						<NavController/>
						<div className={'root'}>
							<App/>
						</div>
					</Router>
				)
			}
			<BeaconListener />
		</Provider>
	)
}

const App = function () {
	const location = useLocation();
	let pathname = location.pathname;

	return (
		<AnimatePresence initial={false}>
			<Switch location={location} key={pathname}>
				<Route path="/builder-landing"><BuilderLanding/></Route>
				<Route path="/design-guide/:type"><DesignGuide.Show/></Route>
				<Route path="/design-guide"><DesignGuide.Index/></Route>
				<Route path="/luminaries"><Luminaries/></Route>
				<Route path="/about"><About/></Route>
				<Route path="/map/:type?"><Map/></Route>
				<Route path="/shapes/:id"><LivingShapes/></Route>
				{/*<Route path="/onboarding"><Onboarding/></Route>*/}

				<Route path="/force-home"><Home/></Route>
				<Route path="/force-web"><Web/></Route>

				<Route path="/"><Landing/></Route>

			</Switch>
		</AnimatePresence>
	)
}

const mountNode = document.getElementById("app");
ReactDOM.render(<AppShell/>, mountNode);
