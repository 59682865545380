import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Portal} from "react-portal";
import {AnimatePresence, motion} from "framer-motion";
import classes from "./Inspector.module.scss";
import {DEFAULT_TRANSITION} from "../../config";
import classNames from "classnames";
import {Lockit} from "../Navigator/Navigator";
import Onboarding from "../../pages/Onboarding/Onboarding";
import {connect} from "react-redux";
import PropTypes from "prop-types";

class Inspector extends Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
	}

	render() {
		const {open} = this.props;

		return (
			<Portal>
				<AnimatePresence>
					{open && this.renderPortal()}
				</AnimatePresence>
			</Portal>
		);
	}

	renderPortal = () => (
		<motion.div
			className={classes.modal}
			initial={{
				y: '-100%',
			}}
			animate={{
				y: 0
			}}
			exit={{
				y: '-100%',
			}}
			transition={{
				ease: DEFAULT_TRANSITION.ease,
				duration: .3
			}}
		>
			<Onboarding/>
			<Lockit/>
		</motion.div>
	)

	renderEntry = ([name, link], closePortal) => {
		const isActive = this.props.match.path === link;

		return (
			<Link to={link} onClick={closePortal} key={link}
				  className={classNames(classes.entry, 'ivar', isActive ? classes.activeLine : null)}>
				{name}
			</Link>
		)
	}
}

export default connect()(withRouter(Inspector));
