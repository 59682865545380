.beaconOverlay {
  position: absolute;
  height: 40vh;
  width: calc(100vw - 2 * var(--GUTTER));
  left: var(--GUTTER);
  border-radius: 16px;
  background: var(--SAND);
  top: 14vh;
  padding: 22px;
  box-sizing: border-box;
  overflow: hidden;

  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

  @media all and (max-height: 700px) {
	top: 60px;
  }
}

.minimized {
  //height: 5vh;

  .backgroundOpaque {
	opacity: 1;
  }

  .background {
	filter: saturate(140%) brightness(80%) blur(15px);
  }

  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.18);
}

.background, .backgroundOpaque {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
}

.background {
  z-index: 0;
  background-size: cover;
  background-position: center;
  filter: saturate(100%) brightness(80%) blur(0px);
}

.backgroundOpaque {
  z-index: 1;
  //backdrop-filter: saturate(140%) blur(15px) brightness(90%);
  //-webkit-backdrop-filter: saturate(140%) blur(15px) brightness(90%);
  //mix-blend-mode: multiply;
  opacity: 0;
}

.content {
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}

.name {
}

.location {
  font-size: 12px;
}

.proximity {
  margin-top: 8px;
}

.close {
  position: absolute;
  color: white;
  z-index: 4;
}

.proximityMinimized {
  position: absolute;
  z-index: 4;
  top: 17px;
  left: 24px;
}

.nameMinimized {
  position: absolute;
  z-index: 4;
  top: 22px;
  left: 94px;
  color: white;
}
