.base {
  padding: 22px 24px 25px 24px;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 23456px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  line-height: 14px;
  font-weight: 500;

  transition: opacity .1s;

  &:active {
	opacity: 0.8;
  }
}

.small {
  display: inline-block;
  width: fit-content;
  padding: 14px 20px 15px;
}

.black {
  background: #333;
  color: white;
}

.white {
  background: white;
  color: black;
}

.gray {
  // nothing here
}

.dimmed {
}

.outlined {
  background: transparent;
  border-width: 2px;
  border-style: solid;

  &.white {
	border-color: white;
	color: black;
  }

  &.gray {
	border-color: #ccc;
	color: black;
  }

  &.dimmed {
	border-color: rgba(255, 255, 255, 0.3);
	color: white;
  }

  &:active {
	background: rgba(100,100,100,.1);
  }
}
