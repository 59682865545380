import React, {Component} from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

// References the `src/styles/fonts.scss` file.

class Typography extends Component {
	static propTypes = {
		type: PropTypes.oneOf([
			'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'muted', 'caps'
		]),
		className: PropTypes.string,
		children: PropTypes.node,
		style: PropTypes.object,
	}

	render() {
		const {type, className, children, ...other} = this.props;
		const Component = 'div';

		return (
			<Component className={classNames(type, className)} {...other}>
				{children}
			</Component>
		);
	}
}

export default Typography;
