import React, {Component} from 'react';
import PropTypes from "prop-types";
import classes from './Button.module.scss';
import classNames from "classnames";
import Tappable from "../Tappable";

class Button extends Component {
	static propTypes = {
		small: PropTypes.bool,
		onClick: PropTypes.func,
		type: PropTypes.oneOf([
			'outlined',
			'filled',
		]),
		color: PropTypes.oneOf([
			'gray',
			'black',
			'white',
			'dimmed',
			'none',
		]),
		children: PropTypes.node,
		className: PropTypes.string,
	}

	static defaultProps = {
		onClick: () => null,
		type: 'filled',
		color: 'black',
		small: false,
	}

	render() {
		const {
			children,
			small,
			onClick,
			color,
			className,
			type,
		} = this.props;

		const classColor = classes[color];
		const classType = classes[type]
		const c = classNames(classes.base, classType, small && classes.small, 'caps', classColor, className)

		return (
			<button
				onClick={onClick}
				className={c}
			>
				{children}
			</button>
		);
	}
}

export default Button;
