import React, {Component, useEffect} from 'react';
import Navbar from "../Navbar";
import {useSelector} from "react-redux";
import Navigator from "../Navigator/Navigator";
import {AnimatePresence, motion} from "framer-motion";
import {FAST_TRANSITION} from "../../config";
import Icon from "../Icon";
import {ICONS} from "../Icon/Icon";
import {NAV_PROPS_INITIAL, navPropsSlice} from "../../store/navPropsSlice";
import Inspector from "../Inspector";
import style from "../Navbar/Navbar.module.scss";
import OnboardingContainer from "../Inspector/OnboardingContainer";
import Typography from "../Typography/Typography";

export const NAV_ITEMS = {
	MENU: 'MENU',
	PROBA_LOGO: 'PROBA_LOGO',
	INFO: 'INFO',
	BACK: 'BACK',
	BACK_RAISED: 'BACK_RAISED',
	BACK_BUBBLE: 'BACK_BUBBLE',
	CLOSE: 'CLOSE',
	MAP: 'MAP',
};

export const NAV_COMPONENTS = {
	MENU: <Navigator/>,
	PROBA_LOGO: <Icon icon={ICONS.TomorrowLand}/>,
	INFO: <OnboardingContainer/>,
	BACK: <Icon icon={ICONS.Arrow}/>,
	BACK_BUBBLE: <Icon icon={ICONS.ArrowColoredCircle}/>,
	CLOSE: <Icon icon={ICONS.Close}/>,
	MAP: <Typography type={'h4'} style={{color: 'currentColor', marginTop: '-8px'}}>Map</Typography>,
	BACK_RAISED: (
		<div className={style.backButtonElevated}>
			<Icon icon={ICONS.ArrowCircle} noOutline/>
		</div>
	)
};

const NAV_EVENT = 'navActions';

export const NAV_ACTIONS = {
	ON_LEFT: 'onLeft',
	ON_RIGHT: 'onRight',

	MENU_OPENED: 'menuOpened',
	MENU_CLOSED: 'menuClosed',

	ONBOARDING_OPENED: 'onboardingOpened',
	ONBOARDING_CLOSED: 'onboardingClosed',
	//...
};

const NavEntry = ({name, component}) => (
	<AnimatePresence exitBeforeEnter>
		<motion.div
			initial={{scale: 0}}
			animate={{scale: 1}}
			exit={{scale: 0}}
			transition={FAST_TRANSITION}
			key={name}
		>
			{component}
		</motion.div>
	</AnimatePresence>
)

export default function NavController() {
	const navProps = useSelector((state) => state.navProps.props);
	const {
		color,
		left,
		center,
		right,
		className,
		fixed,
	} = navProps;

	const navItem = (item) => NAV_COMPONENTS?.[item]

	console.log({
		navProps
	})

	return (
		<Navbar
			color={color}
			className={className}
			fixed={fixed}
			left={(
				<div
					onClick={() => triggerNavAction(NAV_ACTIONS.ON_LEFT)}
				>
					<NavEntry
						component={navItem(left)}
						name={left}
					/>
				</div>
			)}

			center={(
				<div>
					<NavEntry
						name={center}
						component={navItem(center)}
					/>
				</div>
			)}

			right={(
				<div
					onClick={() => triggerNavAction(NAV_ACTIONS.ON_RIGHT)}
				>
					<NavEntry
						component={navItem(right)}
						name={right}
					/>
				</div>
			)}
		/>
	)
}

export const setNavBar = (dispatch, props = {}) => {
	dispatch(navPropsSlice.actions.setNav(props));
}

export const triggerNavAction = (action) => {
	window.dispatchEvent(new CustomEvent(NAV_EVENT, {detail: {action}}))
}

export const addNavActions = (actionSettings, raw = () => null) => {
	const callable = (e) => {
		const action = e.detail.action;
		console.log(action)
		if (actionSettings.hasOwnProperty(action)) {
			actionSettings?.[action]();
		}
		raw(e);
	}

	window.addEventListener(NAV_EVENT, callable);

	return callable;
}

export const removeNavActions = (callable) => {
	window.removeEventListener(NAV_EVENT, callable);
}

