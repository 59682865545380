import React, {Component} from 'react';
import Page from "../../components/Page/Page";
import COLORS, {setBackgroundColor} from "../../styles/colors";
import {Link, withRouter} from "react-router-dom";
import classes from './DesignGuide.module.scss';
import Navbar, {DefaultNavbar} from "../../components/Navbar/Navbar";
import Typography from "../../components/Typography/Typography";
import Icon from "../../components/Icon";
import {ICONS} from "../../components/Icon/Icon";
import Card from "../../components/Card";
import {CARD_TYPES} from "../../components/Card/Card";
import Tabs from "../../components/Tabs";
import classNames from 'classnames';
import {artWeekData, mddData, designMiamiData} from '/src/data/parsed';
import {ContentCard} from "./ContentCard";
import {AnimatePresence} from "framer-motion";
import {FAST_TRANSITION, SWIFT_TRANSITION} from "../../config";
import {motion} from 'framer-motion';
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar
} from "../../components/NavController/NavController";
import {connect} from "react-redux";
import {navPropsSlice} from "../../store/navPropsSlice";

class Index extends Component {
	state = {
		onboardingOpen: false,
	}

	componentDidMount() {
		setNavBar(this.props.dispatch, {
			left: NAV_ITEMS.MENU,
			color: COLORS.WINE,
			right: NAV_ITEMS.INFO,
		})

		this.handler = addNavActions({
			[NAV_ACTIONS.ONBOARDING_OPENED]: this.onboardingOpen,
			[NAV_ACTIONS.ONBOARDING_CLOSED]: this.onboardingClose,
		});
	}

	componentWillUnmount() {
		removeNavActions(this.handler);
	}

	onboardingOpen = () => {
		this.setState({
			onboardingOpen: true,
		})
	}

	onboardingClose = () => {
		this.setState({
			onboardingOpen: false,
		})
	}

	cameBack = () => {
		return this.props.location.search.indexOf('cameBack') >= 0;
	}

	render() {
		const {onboardingOpen} = this.state;

		return (
			<Page
				className={classNames(classes.root, onboardingOpen && classes.hideText)}
				backgroundColor={COLORS.PURP}
				textColor={COLORS.WINE}
				transition={this.cameBack() ? 'right-left' : 'fade-left'}
			>
				<div>
					<Typography type={'h3'} className={classes.title}>
						Design Guide
					</Typography>
					<Typography type={'p'} className={classes.designGuideBody}>
						The physical installation transforms the neighborhood into an interactive playground
					</Typography>
				</div>


				<div className={classes.selectorCards}>
					<Card className={classNames(classes.selectorCard, classes.MDD)} type={CARD_TYPES.OUTLINE}
						  to={'/design-guide/miami-design-district'}>
						<Icon icon={ICONS.MDD}/>
					</Card>

					<Card className={classNames(classes.selectorCard, classes.beyondTheDistrict)} type={CARD_TYPES.OUTLINE}
						  to={'/design-guide/art-week'}>
						<Typography type={'h3'}>
							Art Week <br/>
							Top 20
						</Typography>
					</Card>
				</div>
			</Page>
		);
	}
}

const GUIDE_TYPES = {
	MIAMI_DESIGN_DISTRICT: 'miami-design-district',
	ART_WEEK: 'art-week',
}

class Show extends Component {
	constructor(props) {
		super(props);

		const type = this.getType(this.props.match.params.type);

		if (!type) {
			this.props.history.goBack();
		}

		this.state = {
			type,
			scrolled: false
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll);

		setNavBar(this.props.dispatch, {
			left: NAV_ITEMS.BACK,
			center: null,
			fixed: true,
		})

		this.handler = addNavActions({
			onLeft: this.goBack,
		})
	}

	componentWillUnmount() {
		removeNavActions(this.handler);
		window.removeEventListener('scroll', this.onScroll);
	}

	onScroll = () => {
		requestAnimationFrame(() => {
			const icon = window.scrollY ? NAV_ITEMS.BACK_BUBBLE : NAV_ITEMS.BACK;
			this.props.dispatch(navPropsSlice.actions.setLeft(icon));
		});
	}

	getType = (type) => {
		if (!Object.values(GUIDE_TYPES).includes(type)) {
			return null;
		}

		return type;
	}

	goBack = () => {
		this.props.history.push('/design-guide?cameBack')
	}

	render() {
		const {type, scrolled} = this.state;
		const bg = this.getBackgroundColor(type);

		const content = {
			[GUIDE_TYPES.MIAMI_DESIGN_DISTRICT]: this.renderMDD,
			[GUIDE_TYPES.ART_WEEK]: this.renderArtWeek,
		}[type];

		return (
			<Page
				backgroundColor={bg}
				textColor={COLORS.WINE}
				transition={'left-right'}
			>
				{content()}
			</Page>
		)
	}

	renderArtWeek = () => {
		const entries = artWeekData();

		return (
			<>
				<div className={classes.logo}>
					<Typography type={'h3'}>
						Art Week <br/>
						Top 20
					</Typography>
				</div>
				<Typography type={'p'} className={classes.designGuideBody}>
					Discover the must-see Miami Art Week exhibitions, on view November 30th thru December 5th, 2021.
				</Typography>

				{entries.map(({title, image, venue, description}, i) => (
					<ContentCard
						key={i}
						idx={i}
						title={title}
						image={image}
						body={(
							<>
								{
									venue && (
										<>
											<Typography type={'p'} className={classes.cardAttrKey}>
												Venue
											</Typography>
											<Typography type={'p'} className={classes.cardAttrValue}>
												{venue}
											</Typography>
										</>
									)
								}

								<Typography type={'p'} className={classes.cardAttrKey}>
									Description
								</Typography>
								<Typography type={'p'} className={classes.cardAttrValue}>
									<div dangerouslySetInnerHTML={{__html: description}}/>
								</Typography>
							</>
						)}
					/>
				))}
			</>
		)
	}

	renderMDD = () => {
		const categories = mddData();

		return (
			<>
				<div className={classes.logo}>
					<Icon icon={ICONS.MDD}/>
				</div>
				<Typography type={'p'} className={classes.designGuideBody}>
					We are a creative neighborhood & shopping destination dedicated to innovative fashion, design, art,
					architecture, & dining.
				</Typography>

				<Tabs
					horizontalScroll
					tabs={Object.entries(categories).map(([name, data]) => ({
						name,
						component: this.renderContentCards(data)
					}))}
				/>
			</>
		)
	}

	renderContentCards = (data) => {
		return (
			<div>
				{
					data.map(({title, image, address, phone, description, hours}, i) => (
						<ContentCard
							key={i}
							idx={i}
							title={title}
							image={image}
							body={(
								<>
									<div dangerouslySetInnerHTML={{__html: description}}/>
									{
										phone && (
											<>
												<Typography type={'p'} className={classes.cardAttrKey}>
													Phone
												</Typography>
												<Typography type={'p'} className={classes.cardAttrValue}>
													{phone}
												</Typography>
											</>
										)
									}

									{
										address && (
											<>
												<Typography type={'p'} className={classes.cardAttrKey}>
													Address
												</Typography>
												<Typography type={'p'} className={classes.cardAttrValue}>
													{address}
												</Typography>
											</>
										)
									}
								</>
							)}
						/>
					))
				}
			</div>
		)
	}

	getBackgroundColor = (type) => (
		{
			[GUIDE_TYPES.MIAMI_DESIGN_DISTRICT]: COLORS.MINT,
			[GUIDE_TYPES.ART_WEEK]: COLORS.PINK,
		}[type]
	)
}

export default {
	Index: connect()(withRouter(Index)),
	Show: connect()(withRouter(Show)),
};
