import React, {Component} from 'react';
import Page from "../../components/Page/Page";
import COLORS from "../../styles/colors";
import Navbar from "../../components/Navbar";
import Icon from "../../components/Icon";
import {ICONS} from "../../components/Icon/Icon";
import classes from './BuilderLanding.module.scss';
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button";
import {withRouter} from "react-router-dom";
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar
} from "../../components/NavController/NavController";
import {connect} from "react-redux";
import {viewPage} from "../../utilities";

const exampleShape = new URL('/src/assets/images/landing-builder-exampleshape2.png?width=500&quality=70', import.meta.url);

class BuilderLanding extends Component {

	componentDidMount() {
		setNavBar(this.props.dispatch, {
			left: NAV_ITEMS.CLOSE,
			center: null,
		})

		this.handler = addNavActions({
			[NAV_ACTIONS.ON_LEFT]: this.goHome,
		});
	}

	componentWillUnmount() {
		removeNavActions(this.handler)
	}

	openBuilder = () => {
		viewPage('/builder', 'builder');
		window.location.href = 'uniwebview://startBuilder';
	}

	goHome = () => {
		this.props.history.push('/');
	}

	render() {
		return (
			<Page
				backgroundColor={COLORS.SWAN}
				textColor={COLORS.CHAR}
				transition={'up-down'}
				className={classes.rootContainer}
			>

				<div className={classes.root}>
					<div className={classes.graphic} style={{backgroundImage: `url(${exampleShape})`}}>
						<Grid/>
					</div>

					<Typography className={classes.title} type={'h3'}>
						Build Your Living<br/>
						Shapes
					</Typography>

					<Typography className={classes.paragraph} type={'p'}>
						Build your own sculpture and place it in the AR world.
					</Typography>

					<Button onClick={this.openBuilder} className={classes.button}>
						Get Started
					</Button>
				</div>
			</Page>
		);
	}
}

const Grid = () => {
	return (
		<div className={classes.grid}>
			<svg width="375" height="383" viewBox="0 0 375 383" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0)">
					<rect width="375" height="421" transform="translate(0 -38)" fill="white"/>
					<line x1="187.896" y1="-38" x2="187.896" y2="383" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="180.283" x2="-1324.53" y2="180.283" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="230.684" x2="-1324.53" y2="230.684" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="312.956" x2="-1324.53" y2="312.956" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="144.704" x2="-1324.53" y2="144.704" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="118.763" x2="-1324.53" y2="118.763" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="100.234" x2="-1324.53" y2="100.234" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="83.9265" x2="-1324.53" y2="83.9266" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="71.3262" x2="-1324.53" y2="71.3263" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="61.6914" x2="-1324.53" y2="61.6915" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="52.7966" x2="-1324.53" y2="52.7968" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="46.126" x2="-1324.53" y2="46.1261" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="39.4553" x2="-1324.53" y2="39.4554" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="33.5259" x2="-1324.53" y2="33.526" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="29.0789" x2="-1324.53" y2="29.079" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="24.6311" x2="-1324.53" y2="24.6312" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="20.9255" x2="-1324.53" y2="20.9257" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="17.219" x2="-1324.53" y2="17.2191" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="14.2549" x2="-1324.53" y2="14.255" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="11.2895" x2="-1324.53" y2="11.2897" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="9.06567" x2="-1324.53" y2="9.0658" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line x1="1699.55" y1="6.84179" x2="-1324.53" y2="6.84192" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="4.61889" x2="-1324.53" y2="4.61902" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="2.39599" x2="-1324.53" y2="2.39612" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="1699.55" y1="0.171136" x2="-1324.53" y2="0.17127" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="188.257" y1="-37.7623" x2="-154.623" y2="372.498" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="187.711" y1="-37.6793" x2="-521.615" y2="372.944" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line y1="-0.370599" x2="819.606" y2="-0.370599"
						  transform="matrix(0.865447 0.501001 0.501001 -0.865447 188.264 -38)" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="187.653" y1="-38.3479" x2="1306.3" y2="371.796" stroke="#EAEAEA" strokeWidth="0.741197"/>
					<line y1="-0.370599" x2="1191.46" y2="-0.370599"
						  transform="matrix(-0.938883 0.344236 0.344236 0.938883 187.701 -38)" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line x1="187.952" y1="-37.6423" x2="-1323.68" y2="371.679" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line y1="-0.370599" x2="1566.07" y2="-0.370599"
						  transform="matrix(0.965239 0.261369 0.261369 -0.965239 188.264 -38)" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<line y1="-0.370599" x2="534.678" y2="-0.370599"
						  transform="matrix(0.641283 0.767304 0.767304 -0.641283 188.264 -38)" stroke="#EAEAEA"
						  strokeWidth="0.741197"/>
					<rect y="-38" width="375.046" height="421" fill="url(#paint0_linear)"/>
				</g>
				<defs>
					<linearGradient id="paint0_linear" x1="187.523" y1="24.5" x2="187.523" y2="383"
									gradientUnits="userSpaceOnUse">
						<stop stopColor="white"/>
						<stop offset="0.10603" stopColor="white" stopOpacity="0"/>
						<stop offset="0.889818" stopColor="white" stopOpacity="0.166667"/>
						<stop offset="1" stopColor="white"/>
					</linearGradient>
					<clipPath id="clip0">
						<rect width="375" height="421" fill="white" transform="translate(0 -38)"/>
					</clipPath>
				</defs>
			</svg>
		</div>
	)
}

export default connect()(withRouter(BuilderLanding));
