const CONFIG = {
	media: {
		/**
		 * The base url to use for retrieving the media assets.
		 */
		base_url: 'https://media.3njoy.net',
	}
}

/**
 * Laravel like config getter,
 * you can use dot-notation for nested values,
 * fallback for when there's no or a null entry.
 *
 * @param key
 * @param fallback
 * @returns {*}
 */
export default function config(key, fallback) {
	const keySeries = key.split('.');
	return keySeries.reduce((acc, key) =>  acc[key] ?? fallback, CONFIG);
}

export const DEFAULT_TRANSITION = {
	duration: 1,
	ease: [0.685, 0.1, 0.25, 0.8],
}

export const FAST_TRANSITION = {
	ease: [0.685, 0.1, 0.25, 0.8],
	duration: .2,
}

export const SWIFT_TRANSITION = {
	ease: [0.685, 0.1, 0.25, 0.8],
	duration: .3,
}
