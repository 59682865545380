import React, {Component} from 'react';
import classes from './Map.module.scss';
import Page from "../../components/Page/Page";
import COLORS from "../../styles/colors";
import {withRouter} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion';
import {getLocations, toLocations} from "./Places";
import {Portal} from "react-portal";
import Typography from "../../components/Typography/Typography";
import {get} from 'lodash';
import {connect} from "react-redux";
import {toCachedUrl} from "../../utilities";
import {
	addNavActions,
	NAV_ACTIONS,
	NAV_ITEMS,
	removeNavActions,
	setNavBar
} from "../../components/NavController/NavController";
import {DEFAULT_TRANSITION, SWIFT_TRANSITION} from "../../config";
import {JustTabs} from "../../components/Tabs/Tabs";

const mapImage = new URL('/src/assets/images/map.jpg?as=webp&width=2000&quality=75', import.meta.url);
const lowRes = new URL('/src/assets/images/map.jpg?as=webp&width=200&quality=80', import.meta.url);

const ORIGINAL_MAP_DIMENSIONS = {
	width: 1982,
	height: 1463,
}

const MAP_DIMENSIONS = {
	width: 1982,
	height: 1463,
}

const halves = {
	left: -(MAP_DIMENSIONS.width / 2),
	right: MAP_DIMENSIONS.width / 2,
	top: -(MAP_DIMENSIONS.height / 2),
	bottom: MAP_DIMENSIONS.height / 2,
}

const constraints = {
	left: halves.left + MAP_DIMENSIONS.width * .05,
	right: halves.right - MAP_DIMENSIONS.width * .1,
	top: halves.top + MAP_DIMENSIONS.width * .2,
	bottom: halves.bottom - MAP_DIMENSIONS.width * .1,
}

const toRelativeX = (absolute) => {
	return (absolute / ORIGINAL_MAP_DIMENSIONS.width) * MAP_DIMENSIONS.width;
}

const toRelativeY = (absolute) => {
	return (absolute / ORIGINAL_MAP_DIMENSIONS.height) * MAP_DIMENSIONS.height;
}

const VIEWS = {
	STUDIO_PROBA: 'Studio Proba',
	LUMINARIES: 'Luminaries',
}

class Map extends Component {
	constructor(props) {
		super(props);

		this.state = {
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			pin: null,
			zoom: 1,
			transition: 'fade',
			mapLoaded: false,
			activeView: VIEWS.STUDIO_PROBA,
		}
	}

	selectPin = (pin) => {
		this.setState({pin});
	}

	componentDidMount() {
		setNavBar(this.props.dispatch, {
			// color: COLORS.WINE,
			// left: NAV_ITEMS.BACK,
			// center: NAV_ITEMS.MAP,
			left: NAV_ITEMS.BACK_RAISED,
			center: null,
		})

		this.handler = addNavActions({
			[NAV_ACTIONS.ON_LEFT]: () => this.props.history.push('/'),
		})

		this.checkView();
	}

	checkView = () => {
		const view = this.props.match.params.type;

		if (!Object.keys(VIEWS).map(i => i.toLowerCase()).includes(view)) {
			return;
		}

		this.setState({
			activeView: VIEWS[view.toUpperCase()],
		})
	}

	componentWillUnmount() {
		removeNavActions(this.handler);
	}

	deselect = () => this.selectPin(null)

	openLivingShape = (totem) => () => {
		this.props.history.push(`/shapes/${totem.internalReference}?allowBack`)
	}

	render() {
		const {transition} = this.state;

		return (
			<Page
				className={classes.root}
				backgroundColor={COLORS.PINK}
				textColor={COLORS.WINE}
				transition={transition}
			>
				{this.renderNav()}
				{this.renderMap()}
				{this.renderSelectedPin()}
			</Page>
		);
	}

	renderNav = () => {
		const {activeView} = this.state;
		const tabIndex = Object.values(VIEWS).findIndex((value) => value === activeView);

		return (
			<div className={classes.nav}>
				<JustTabs
					className={classes.tabs}
					tabClassName={classes.tab}
					tabs={Object.entries(VIEWS).map(([key, value]) => ({name: value}))}
					currentTabIndex={tabIndex}
					tabClicked={(idx) => {
						this.setState({
							activeView: Object.values(VIEWS)[idx]
						});
						window.history.replaceState(null, null, `/map/${Object.keys(VIEWS)[idx].toLowerCase()}`)
					}}
				/>
			</div>
		)
	}

	renderMap = () => {
		const {screenWidth, screenHeight, mapLoaded, zoom} = this.state;

		return (
			<motion.div
				className={classes.map}
				style={{
					width: `${MAP_DIMENSIONS.width}px`,
					left: `${halves.left + (screenWidth / 2)}px`,
					top: `${halves.top + (screenHeight / 2)}px`,
					scale: zoom
				}}
				initial={false}
				drag={true}
				dragConstraints={constraints}
				dragElastic={0.8}
				onClick={this.deselect}
			>
				{/*<MapVector/>*/}
				<motion.img
					animate={{
						opacity: mapLoaded ? 1 : 0,
					}}
					transition={DEFAULT_TRANSITION}
					src={mapImage}
					onLoad={() => {
						setTimeout(() => {
							this.setState({
								mapLoaded: true
							});
						}, 400)
					}}
				/>

				{this.renderItems()}
			</motion.div>
		)
	}

	renderSelectedPin = () => {
		const {pin} = this.state;

		return (
			<Portal>
				<AnimatePresence exitBeforeEnter>
					{
						pin && (
							<motion.div
								key={pin}
								initial={{
									y: '120px',
									opacity: 0,
								}}
								animate={{
									y: '0',
									opacity: 1,
								}}
								exit={{
									y: '120px',
									opacity: 0,
								}}
								// transition={FAST_TRANSITION}
								className={classes.selectedTray}
							>
								{this.renderTrayElements(pin)}
							</motion.div>
						)
					}
				</AnimatePresence>
			</Portal>
		)
	}

	renderTrayElements = (pin) => {
		const locations = get(toLocations(this.props.totems, this.state.activeView === VIEWS.LUMINARIES), `${pin}.places`, []);

		return (
			<>
				{
					locations.map((location, i) => (
						<div
							onClick={this.openLivingShape(location)}
							key={`${pin}.${i}`}
							className={classes.trayElementContainer}
							style={{
								width: locations.length > 1 ? 'calc(100% - 50px)' : 'calc(100%)',
							}}
						>
							<div className={classes.trayElement}>


								<div
									className={classes.trayElementImage}
									style={{
										backgroundImage: `url(${toCachedUrl(get(location, 'primaryImage.url'))})`
									}}
								>

								</div>
								<div className={classes.trayElementContent}>
									<Typography type={'caps'} className={classes.trayElementTitle}>
										{
											location.isLuminaries ? 'LIVING SHAPE' : `LIVING SHAPE ${location.internalReference}`
										}
									</Typography>
									<Typography type={'h3'} className={classes.trayElementLocation}>
										{
											location.isLuminaries ? `by ${location.artistName}` : location.location
										}
									</Typography>
								</div>
							</div>
						</div>
					))
				}
			</>
		)
	}

	renderPlace = (key, place) => {
		const size = 44;
		const active = this.state.pin === key;
		const imgs = place.places.filter((place) => {
			return place.primaryImage && place.primaryImage.url;
		}).map(({primaryImage}) => primaryImage.url);

		const bg = imgs.length > 0 ? toCachedUrl(imgs[0]) : null;

		return (
			<motion.div
				key={key}
				className={classes.place}
				onClick={(e) => {
					e.stopPropagation();
					this.selectPin(key)
				}}
				style={{
					x: '-25%',
					y: '-25%',
					width: `${size}px`,
					height: `${size}px`,
					top: toRelativeY(place.y),
					left: toRelativeX(place.x),
					borderRadius: place.places.length > 1 ? '10px' : `50%`,
					backgroundImage: `url(${bg})`,
				}}
				animate={{
					borderColor: active ? COLORS.ROSE : COLORS.SWAN,
					boxShadow: active ? `0 8px 10px rgba(0, 0, 0, 0.2)` : `0 4px 4px rgba(0, 0, 0, 0.15)`
				}}
			>
				{place.name}
				{place.places.length > 1 && (
					<div className={classes.placeCounter}>
						{place.places.length}
					</div>
				)}
			</motion.div>
		)
	}

	renderItems = () => {
		const {mapLoaded} = this.state;

		return (
			<AnimatePresence>
				{
					mapLoaded && (
						<motion.div
							initial={{opacity: 0}}
							animate={{opacity: 1}}
							exit={{opacity: 0}}
							transition={{delay: .2}}
						>
							{Object.entries(toLocations(this.props.totems, this.state.activeView === VIEWS.LUMINARIES)).map(([key, place]) => this.renderPlace(key, place))}
						</motion.div>
					)
				}
			</AnimatePresence>
		)
	}
}

const mapStateToProp = state => {
	const totems = state.totems?.totems?.totems ?? [];
	return {totems};
}

export default connect(mapStateToProp)(withRouter(Map));
