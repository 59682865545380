$padding: 35px;

.root {
  height: 72px;
  padding-top: calc(var(--sat, 44px) + 14px);

  &.rootWeb {
	height: 72 + $padding;
  }
}

.bar {
  position: fixed;
  -webkit-transform: translateZ(0);
  z-index: 10;
  top: calc(var(--sat, 44px) + 14px);
  left: 0;
  width: calc(100vw - 2 * var(--GUTTER));
  color: var(--text-color);
  box-sizing: border-box;
  margin: 0 var(--GUTTER);
  transition: 0.2s transform var(--EASE);

  &.barWeb {
	margin-top: $padding;
  }
}

.entry {
  //margin: 18px;
  top: 0;
  position: absolute;
  z-index: 3;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.center {
  z-index: 0;
  position: relative;
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.backButtonElevated {
  svg {
	border-radius: 50%;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  }

  &:active {
	opacity: .8;
  }
}
