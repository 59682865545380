import React, {Component} from 'react';
import PropTypes from "prop-types";
// import {tweenPaths} from 'svg-tween'
import {DEFAULT_TRANSITION} from "../../config";

const DIRECTIONS = {
	up: 'M1 1L15.9999 11L31 1',
	down: 'M1 11L15.9999 0.999997L31 11',
}

class Chevron extends Component {
	static propTypes = {
		direction: PropTypes.oneOf(['up', 'down']),
		color: PropTypes.string,
		onClick: PropTypes.func,
	}

	static defaultProps = {
		direction: 'up',
		color: '#ccc',
	}

	constructor(props) {
		super(props);


		this.state = {
			d: DIRECTIONS[this.props.direction],
		}

		this.svg = React.createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {direction} = this.props;

		if (prevProps.direction === direction) {
			return;
		}

		this.tweenTo(DIRECTIONS[direction]);
	}

	tweenTo = (to) => {
		// tweenPaths({
		// 	duration: 200,
		// 	from: this.state.d,
		// 	easing: `easeInCubic`,
		// 	to,
		// 	next: d => this.svg.current.setAttribute('d', d)
		// })

		this.setState({
			d: to
		})
	}

	render() {
		const {d} = this.state;
		const {color, onClick} = this.props;

		return (
			<svg onClick={onClick} width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d={d}
					width={30}
					height={10}
					color={color}
					ref={this.svg}
					stroke="#CCCCCC" strokeWidth="1.5"
				/>
			</svg>

		);
	}
}


export default Chevron;
