import React, {Component} from 'react';
import classes from './Card.module.scss';
import PropTypes from "prop-types";
import classNames from "classnames";
import {withRouter} from "react-router-dom";

export const CARD_TYPES = {
	OUTLINE: 'outline',
	CONTAIN: 'contain',
}

class Card extends Component {
	static propTypes = {
		type: PropTypes.oneOf(Object.values(CARD_TYPES)),
		children: PropTypes.node.isRequired,
		className: PropTypes.string,
		noMargin: PropTypes.bool,
		onClick: PropTypes.func,
		to: PropTypes.string,
	}

	static defaultPropTypes = {
		type: CARD_TYPES.CONTAIN,
		noMargin: false
	}

	nav = () => {
		const {to} = this.props;
		if (!to) {
			return;
		}

		this.props.history.push(to);
	}

	render() {
		const {type, children, onClick = this.nav, noMargin, className} = this.props;

		const cardType = classes[type];

		return (
			<div onClick={onClick} className={classNames(classes.card, cardType, noMargin ? classes.noMargin : null, className)}>
				{children}
			</div>
		);
	}
}

export default withRouter(Card);
