import React, {Component, useEffect} from "react";
import classes from "./Carousel.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useInView} from "react-intersection-observer";
import {motion} from 'framer-motion';
import {animate, cubicBezier} from "popmotion";
import {DEFAULT_TRANSITION} from "../../config";

class Carousel extends Component {
	static propTypes = {
		slides: PropTypes.arrayOf(PropTypes.node),
		containerClass: PropTypes.string,
		slideClass: PropTypes.string,
		selectorGroupClass: PropTypes.string,
		selectorClass: PropTypes.string,
		hideSelector: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			active: 0,
		};

		this.container = React.createRef();
	}

	render() {
		const {containerClass, selectorGroupClass, selectorClass, hideSelector = false} = this.props;

		return (
			<div className={classNames(classes.container, containerClass)}>
				{this.renderSlides()}
				{hideSelector === false && this.renderSelector()}
			</div>
		);
	}

	scrollTo = (idx) => {
		const width = window.innerWidth;
		const comp = this.container.current;
		const from = comp.scrollLeft;
		const to = idx * (width - 48);


		animate({
			from,
			to,
			type: 'spring',
			bounce: .2,
			duration: 550,
			onUpdate: latest => {
				console.log({latest});
				comp.scrollLeft = latest;
			}
		})
	}

	renderSelector = () => {
		const {active} = this.state;
		return (
			<div className={classes.selector}>
				{
					this.props.slides.map((e, idx) => (
						<motion.div
							key={idx}
							className={classes.selectorDot}
							onClick={() => this.scrollTo(idx)}
							animate={{
								backgroundColor: idx === active ? `rgba(255, 255, 255, 1)` : `rgba(255, 255, 255, 0.3)`
							}}
						/>
					))
				}
			</div>
		)
	}

	onInView = (idx) => {
		this.setState({
			active: idx,
		})
	}

	renderSlides = () => {
		const {slides, slideClass} = this.props;

		return (
			<div className={classes.slides} ref={this.container}>
				{slides.map((slide, idx) => (
					<Slide
						key={idx}
						className={classNames(classes.slide, slideClass)}
						onInView={() => this.onInView(idx)}
					>
						{slide}
					</Slide>
				))}
			</div>
		);
	};
}

const Slide = ({children, onInView = () => null, ...other}) => {
	const {ref, inView, entry} = useInView({
		/* Optional options */
		threshold: .6,
	});

	useEffect(() => {
		if (inView) {
			onInView();
		}
	}, [inView])

	return (
		<div ref={ref} {...other}>
			{children}
		</div>
	)
}

export default Carousel;
