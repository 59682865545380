import React, {Component} from 'react';
import PropTypes from "prop-types";
import Icons from '../../assets/icons';

// based on `/src/assets/icons/index.js`
export const ICONS = {
	Arrow: 'Arrow',
	ArrowCircle: 'ArrowCircle',
	ArrowColoredCircle: 'ArrowColoredCircle',
	Check: 'Check',
	Close: 'Close',
	Hamburger: 'Hamburger',
	Info: 'Info',
	Trash: 'Trash',
	TomorrowLand: 'TomorrowLand',
	MDD: 'MDD',
	DesignMiami: 'DesignMiami',
	StudioProba: 'StudioProba',
	Enjoy: 'Enjoy',
};

class Icon extends Component {
	static propTypes = {
		icon: PropTypes.oneOf(Object.values(ICONS)),
	}

	render() {
		const {icon, ...other} = this.props;
		const Icon = Icons[icon];

		return (
			<Icon {...other} />
		);
	}
}

export default Icon;
