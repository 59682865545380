import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {isApp} from "../../utilities";
import Home from "../Home/Home";
import Web from "../Web";

class Landing extends Component {
	render() {
		return isApp() ? <Home/> : <Web/>
	}
}

export default withRouter(Landing);
