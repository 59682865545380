import React, {Component} from 'react';
import {connect} from "react-redux";
import {setNavBar} from "../../components/NavController/NavController";
import classes from './Luminaries.module.scss';
import Page from "../../components/Page/Page";
import COLORS from "../../styles/colors";
import Typography from "../../components/Typography/Typography";
import Card from "../../components/Card/Card";
import {toCachedUrl} from "../../utilities";
import {get} from "lodash";
import Tappable from "../../components/Tappable";
import {withRouter} from "react-router-dom";

class Luminaries extends Component {

	componentDidMount() {
		setNavBar(this.props.dispatch, {
			center: null,
			color: COLORS.WINE,
		})

		console.log(this.props)
	}

	getLuminaries = () =>
		this.props?.totems.filter(({isLuminaries}) => isLuminaries) ?? []

	openLuminary = (luminary) => {
		this.props.history.push(`/shapes/${luminary.internalReference}?allowBack`);
	}

	render() {
		const luminaries = this.getLuminaries();

		return (
			<Page
				className={classes.root}
				backgroundColor={COLORS.MINT}
				textColor={COLORS.WINE}
			>
				<div className={classes.content}>
					<Typography type={'h3'}>
						Luminaries
					</Typography>
					<Typography type={'p'} className={classes.body}>
						Sixteen guest creative luminaries have designed sculptures for you to discover in AR.
					</Typography>
				</div>

				{this.renderCards(luminaries)}
			</Page>
		);
	}

	renderCards = (luminaries) => (
		<div className={classes.cards}>
			{
				luminaries.map((luminary) => (
					<Tappable
						onClick={() => this.openLuminary(luminary)}
						className={classes.card}
						key={luminary.id}
					>
						<div
							className={classes.cardImg}
							style={{backgroundImage: `url(${toCachedUrl(get(luminary, 'primaryImage.url'))})`}}
						/>
						<div>
							<Typography type={'h5'}>
								{luminary.artistName}
							</Typography>
						</div>
					</Tappable>
				))
			}
		</div>
	)
}

const mapStateToProp = state => {
	const totems = state.totems?.totems?.totems ?? [];
	return {totems};
}

export default connect(mapStateToProp)(withRouter(Luminaries));
