import {ApolloClient, InMemoryCache, gql} from "@apollo/client/core";

const client = new ApolloClient({
	uri: 'https://api-us-east-1.graphcms.com/v2/ckuwr6fic2r1o01z73mde1ypb/master',
	cache: new InMemoryCache(),
});

export const totems = async () => {
	return await client.query({
		query: gql`
			{
			  totems(orderBy: internalReference_DESC) {
				id
				internalReference
				coordinatesTop
				coordinatesLeft
				isLuminaries
				artistName
				subTitle
				auctionedAt
				location
				primaryImage {
				  id
				  url(transformation: {image: {resize: {width: 900, fit: clip}}})
				}
				hqImage: primaryImage {
				  id
				  url(transformation: {image: {resize: {width: 1800, fit: clip}}})
				}
				shapes {
				  internalReference
				  shapeId
				  primaryImage {
					id
					url(transformation: {image: {resize: {width: 900, fit: clip}}})
				  }
				  name
				}
			  }
			}
		`
	})
}
