import React, {Component} from 'react';
import {Portal} from "react-portal";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion";
import classes from './BeaconOverlay.module.scss';
import {DEFAULT_TRANSITION, FAST_TRANSITION, SWIFT_TRANSITION} from "../../config";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Typography from "../Typography/Typography";
import Tag from "../Tag";
import Icon from "../Icon";
import {ICONS} from "../Icon/Icon";
import {BEACON_STYLE, beaconSlice} from "../../store/beaconSlice";
import classNames from "classnames";

class BeaconOverlay extends Component {
	state = {
		cardPress: false,
	}

	getTotem = () => {
		const {totemId, style} = this.props.beacon;

		if (!totemId || style === BEACON_STYLE.CLOSED) {
			return null;
		}

		const totem = this.props.totems.find((totem) => totem.internalReference === totemId)
		console.log({
			totem
		})
		return totem;
	}

	touchStart = () => {
		this.setState({cardPress: true});
	}

	touchEnd = () => {
		this.setState({cardPress: false});
	}

	openTotem = () => {
		this.props.history.push(`/shapes/${this.getTotem().internalReference}?allowBack&fromBeacon`);
	}

	sizeDown = (e) => {
		e.stopPropagation();
		const {style} = this.props.beacon;

		if (style === BEACON_STYLE.MAXIMIZED) {
			this.props.dispatch(beaconSlice.actions.minimize());
			return;
		}

		if (style === BEACON_STYLE.MINIMIZED) {
			this.props.dispatch(beaconSlice.actions.close())
		}
	}

	sizeUp = () => {
		const {style} = this.props.beacon;

		if (style === BEACON_STYLE.MAXIMIZED) {
			this.openTotem()
			return;
		}

		if (style === BEACON_STYLE.MINIMIZED) {
			this.props.dispatch(beaconSlice.actions.maximize())
		}
	}

	render() {
		const totem = this.getTotem();
		const {style} = this.props.beacon;
		const {cardPress} = this.state;

		return (
			<Portal>
				<AnimatePresence>
					{totem && this.renderTotemCard(totem, cardPress, style)}
				</AnimatePresence>
			</Portal>
		);
	}

	renderTotemCard = (totem, cardPress, style) => (
		<motion.div
			key={totem.internalReference}
			initial={{
				y: '-10vh',
				opacity: 0,
				scale: 1,
			}}
			animate={{
				y: '0vh',
				opacity: 1,
				scale: cardPress ? .95 : 1,
				height: style === BEACON_STYLE.MAXIMIZED ? '40vh' : '60px',
			}}
			exit={{
				y: '-10vh',
				opacity: 0,
				scale: 1,
			}}
			transition={cardPress ? FAST_TRANSITION : SWIFT_TRANSITION}
			className={classNames(classes.beaconOverlay, style === BEACON_STYLE.MINIMIZED && classes.minimized)}
			onClick={this.sizeUp}

			onTapStart={this.touchStart}
			onTouchStart={this.touchStart}
			onMouseDown={this.touchStart}

			onTouchEnd={this.touchEnd}
			onMouseUp={this.touchEnd}
		>
			<AnimateSharedLayout>
				{this.renderInternal(totem, cardPress, style)}
			</AnimateSharedLayout>
		</motion.div>
	)

	renderInternal = (totem, cardPress, style) => {
		return (
			<>
				<motion.div
					className={classes.close}
					onClick={this.sizeDown}
					initial={false}
					animate={{
						top: style === BEACON_STYLE.MAXIMIZED ? '20px' : '6px',
						right: style === BEACON_STYLE.MAXIMIZED ? '20px' : '8px',
						rotate: style === BEACON_STYLE.MAXIMIZED ? '0deg' : '90deg',
					}}
					transition={FAST_TRANSITION}
				>
					<Icon icon={ICONS.Close}/>
				</motion.div>
				{
					totem.primaryImage && (
						<>
							<div
								className={classes.background}
								style={{
									backgroundImage: `url(${totem.primaryImage.url})`,
								}}
							/>

							<div className={classes.backgroundOpaque}/>
						</>
					)
				}
				{
					style === BEACON_STYLE.MAXIMIZED && (
						<div className={classes.content}>
							<Typography type={'h3'} className={classes.name}>
								Living Shapes <br/>
								No. {totem.internalReference}
							</Typography>
							<Typography type={'caps'} className={classes.location}>
								{totem.location}
							</Typography>

							<motion.div className={classes.proximity} layoutId={'nearby'} layout="position">
								<Tag>
									Nearby
								</Tag>
							</motion.div>
						</div>
					)
				}

				{
					style === BEACON_STYLE.MINIMIZED && (
						<>
							<motion.div layout="position" className={classes.proximityMinimized} layoutId={'nearby'}>
								<Tag>
									Nearby
								</Tag>
							</motion.div>
						</>
					)
				}
				<AnimatePresence>
					{
						style === BEACON_STYLE.MINIMIZED && (
							<motion.div
								className={classes.nameMinimized}
								initial={{
									x: '-10px',
									opacity: 0,

								}}

								animate={{
									x: '0px',
									opacity: 1,
									transition: {
										duration: .4,
										delay: .6,
										ease: DEFAULT_TRANSITION.ease,
									}
								}}

								exit={{
									x: '-10px',
									opacity: 0,
								}}
								transition={{
									delay: 0,
									duration: .1,
									ease: DEFAULT_TRANSITION.ease,
								}}
							>
								<Typography type={'caps'}>
									Living Shape {totem.internalReference}
								</Typography>
							</motion.div>
						)
					}
				</AnimatePresence>
			</>
		)
	}
}


const mapStateToProp = state => {
	const totems = state.totems?.totems?.totems ?? [];
	const beacon = state.beacon;
	return {totems, beacon};
}

export default connect(mapStateToProp)(withRouter(BeaconOverlay));
