import React, {Component} from 'react';
import Page from "../../components/Page/Page";
import COLORS from "../../styles/colors";
import {withRouter} from "react-router-dom";
import Navbar from "../../components/Navbar";
import classes from './Onboarding.module.scss';
import Carousel from "../../components/Carousel";
import Typography from "../../components/Typography/Typography";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import {ICONS} from "../../components/Icon/Icon";

const ONBOARDING_IMAGES = {
	1: new URL('/src/assets/images/onboarding-1.png?width=700&quality=75&as=webp', import.meta.url),
	2: new URL('/src/assets/images/onboarding-2.png?width=700&quality=75&as=webp', import.meta.url),
	3: new URL('/src/assets/images/onboarding-3.png?width=700&quality=75&as=webp', import.meta.url),
	4: new URL('/src/assets/images/onboarding-4.png?width=700&quality=75&as=webp', import.meta.url),
	5: new URL('/src/assets/images/onboarding-luminaries.png?width=700&quality=75&as=webp', import.meta.url),
	BG: new URL('/src/assets/images/onboarding-bg-focus.png?width=700&quality=75&as=webp', import.meta.url),
}

class Onboarding extends Component {
	goHome = () => this.props.history.push('/')

	render() {
		return (
			<div className={classes.overlay}>
				{this.renderCarousel()}
			</div>
		);
	}

	renderBackground = () => {
		return (
			<div
				className={classes.background}
				style={{
					backgroundImage: `url(${ONBOARDING_IMAGES.BG})`
				}}
			/>
		)
	}

	renderCarousel = () => {
		return (
			<Carousel
				containerClass={classes.carousel}
				slides={[
					<SlideCard
						image={ONBOARDING_IMAGES['1']}
						title={'Welcome'}
						body={(
							<>
								Tomorrow Land is a celebration of community and collaboration, expressed using the
								vibrant shapes, colors and patterns of Studio Proba.
								<br/> <br/>
								Enjoy the art in person, create your own in the app—then share your creations in a
								virtual AR wonderland.
							</>
						)}
					/>,
					<SlideCard
						image={ONBOARDING_IMAGES['2']}
						title={'Map'}
						body={(
							<>
								Navigate your way around the district and find Studio Proba’s Living Shapes and other
								local attractions. Learn about the artwork in the app, and visit each piece in person.
								When you do, you can begin collecting your own shapes—then design your own!
							</>
						)}
					/>,
					<SlideCard
						image={ONBOARDING_IMAGES['3']}
						title={'Build'}
						body={(
							<>
								With the shapes you’ve collected during your in person visit, let your creativity run
								wild. Compose your own designs—and immerse yourself in a playful world of pattern,
								color, and shape—using our digital builder. When it’s done, place it in virtual space
								using for anyone to find.
							</>
						)}
					/>,
					<SlideCard
						image={ONBOARDING_IMAGES['5']}
						title={'Digital Luminaries'}
						body={(
							<>
								Sixteen guest creative luminaries have designed sculptures for you to discover in AR.
							</>
						)}
					/>,
					<SlideCard
						image={ONBOARDING_IMAGES['4']}
						title={'Guide'}
						body={(
							<>
								To enrich your time with us, we’ve included design guides for the Miami Design District
								and beyond. Discover all that the District and its surrounding neighborhoods have to
								offer. You’ll find art, shopping, dining, entertainment, plus useful info to help you
								plan your visit.
							</>
						)}
					/>,
				]}
			/>
		)
	}
}

const SlideCard = ({title, body, image, ...other}) => (
	<Card className={classes.slideCard} {...other} noMargin>
		<div className={classes.cardImage} style={{backgroundImage: image && `url(${image})`}}/>

		<Typography className={classes.cardTitle} type={'h4'}>
			{title}
		</Typography>
		<Typography className={classes.cardBody} type={'p'}>
			{body}
		</Typography>
	</Card>
)

export default withRouter(Onboarding);
