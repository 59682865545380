import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {fetchTotems} from "../store/totemsSlice";
import {load} from "sass";

const LOAD_TYPES = {
	TOTEMS: 'totems',
	FONTS: 'fonts',
	// IMAGES: 'images',
}

let loaded = [];

export default function DataLoader({onLoaded}) {
	const dispatch = useDispatch()
	// const [loaded, setLoaded] = useState([]);

	const addLoaded = (newLoaded) => {
		loaded = newLoaded;
		if (newLoaded.length === Object.entries(LOAD_TYPES).length) {
			onLoaded();
		}

		// setLoaded(newLoaded)
	}

	useEffect(() => {
		const result = dispatch(fetchTotems());

		result.then(() => {
			addLoaded([...loaded, LOAD_TYPES.TOTEMS])
		})
	}, []);

	useEffect(() => {
		document.fonts.load("12px basis").then(() => {
			console.log({
				ready: 'yes'
			})
			addLoaded([...loaded, LOAD_TYPES.FONTS])
		});
	}, []);

	console.log({
		loaded
	})


	return null;
}
