import {createSlice} from '@reduxjs/toolkit'
import {NAV_ITEMS} from "../components/NavController/NavController";

export const NAV_PROPS_INITIAL = {
	left: 'MENU',
	center: 'PROBA_LOGO',
	right: null,
	color: null,
	fixed: false,
	hide: false,
	className: null,
}

export const navPropsSlice = createSlice({
	name: 'navProps',
	initialState: {
		props: NAV_PROPS_INITIAL
	},
	reducers: {
		setLeft(state, {payload}) {
			state.props.left = payload;
		},
		setCenter(state, {payload}) {
			state.props.center = payload;
		},
		setRight(state, {payload}) {
			state.props.right = payload;
		},
		setColor(state, {payload}) {
			state.props.color = payload;
		},
		setFixed(state, {payload}) {
			state.props.fixed = payload;
		},
		setHide(state, {payload}) {
			state.props.hide = payload;
		},
		setClassName(state, {payload}) {
			state.props.className = payload;
		},
		setNav(state, {payload}) {
			state.props = {
				...NAV_PROPS_INITIAL,
				...payload,
			};
		}
	},
})

// Action creators are generated for each case reducer function
export const {} = navPropsSlice.actions

export default navPropsSlice.reducer
